// React
import React from "react";
// Framework
import { usePortal, forwardType, App, Permission } from "@mefisto/web";
// Icon
import { OrderingIcon } from "icons";
// Loader
import { Loader } from "loader";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default forwardType(App, () => {
  // Framework
  const { config } = usePortal();
  const {
    appsOrderingEnabled,
    appsOrderingUrl,
    appsOrderingLearnUrl,
  } = config.values;
  // Render
  return (
    <App
      visible
      name="ORDERING"
      type="ORGANIZATION"
      enabled={appsOrderingEnabled}
      title="Ordering"
      description="Create your orders and send them all by text or email."
      color="#c756bf"
      priority={4}
      path={appsOrderingUrl}
      learnPath={appsOrderingLearnUrl}
      icon={<OrderingIcon />}
      loader={<Loader />}
      hasDocuments={true}
      pricing={{
        month: 9,
        year: 90,
      }}
      logo={{
        icon: "app-icon-inverted-30",
        title: "Ordering",
      }}
      theme={{
        palette: {
          primary: {
            dark: "#111b1f",
            main: "#273542",
            light: "#8d8d8d",
          },
          secondary: {
            dark: "#92348c",
            main: "#c756bf",
            light: "#c6a1c3",
          },
        },
      }}
      // prettier-ignore
      navigation={{
        basename: "ordering",
        routes: {
          default: "/:organization/locations/:location/cart",
          notAuthorized: "/forbidden",
        },
        paths: {
          orderPublic: "/:organization/locations/:location/o/:uuid",
          notAuthorized: "/forbidden",
          organization: "/:organization",
          location: "/:organization/locations/:location",
          dashboard: "/:organization/locations/:location/dashboard",
          cart: "/:organization/locations/:location/cart",
          categories: "/:organization/locations/:location/categories",
          category: "/:organization/locations/:location/categories/:category",
          vendors: "/:organization/locations/:location/vendors",
          vendor: "/:organization/locations/:location/vendors/:vendor",
          orders: "/:organization/locations/:location/orders",
          order: "/:organization/locations/:location/orders/:order",
          products: "/:organization/locations/:location/products",
          product: "/:organization/locations/:location/products/:product",
          documents: "/:organization/locations/:location/documents",
        },
      }}
      resource={{
        source: "navigation",
        redirectOnChange: true,
      }}
      permissions={[
        Permission("cartItem.query", "Can read cart item data"),
        Permission("cartItem.mutation", "Can read cart item data"),
        Permission("cartOrder.query", "Can read cart order data"),
        Permission("cartOrder.mutation", "Can read cart order data"),
        Permission("category.query", "Can read category data"),
        Permission("category.mutation", "Can edit category data"),
        Permission("import.mutation", "Can import data"),
        Permission("order.query", "Can read order data"),
        Permission("order.mutation", "Can edit order data"),
        Permission("parse.query", "Can read parsed imported data"),
        Permission("product.query", "Can read product data"),
        Permission("product.mutation", "Can edit product data"),
        Permission("vendor.query", "Can read vendor data"),
        Permission("vendor.mutation", "Can edit vendor data"),
      ]}
    />
  );
});
