// React
import { useMemo, useCallback, useEffect, useRef } from "react";
// Helpers
import { noop } from "@mefisto/utils";
// Framework
import { usePortal } from "stack";
import { getData, useLazyQuery, useReactiveVar } from "model/core";
import { useResources } from "resource";
import { useDeepMemo } from "hooks";

/**
 * Performs read operation on an entity in a lazy mode.
 * In other words, you need to call the `fetch` function
 * to perform the read.
 * @param Entity
 * @param options {{input, resources, languages, fetchPolicy, tags, disableAutoRefresh }}
 */
export const useEntityReadLazy = (Entity, options = {}) => {
  // Props
  const {
    input,
    resources: customResources,
    languages,
    fetchPolicy,
    nextFetchPolicy,
    tags,
    disableAutoRefresh,
  } = useDeepMemo(() => options, [options]);
  // Framework
  const { log } = usePortal();
  // Resources
  const { resources: defaultResources } = useResources();
  const resources = useMemo(() => {
    return customResources ?? defaultResources;
  }, [customResources, defaultResources]);
  // Refresh
  const refreshToken = useReactiveVar(Entity.refreshToken);
  const refreshCount = useRef(-1);
  // Memo
  const Tags = useMemo(() => {
    const { READ } = tags ?? Entity.Tags;
    if (!READ) {
      throw TypeError(`[READ] tag must be defined in entity.`);
    }
    return { READ };
  }, [Entity, tags]);
  // Callback
  const getVariables = useCallback(
    ({ input, resources, languages } = {}) => ({
      ...(input && { input }),
      ...(resources && { resources }),
      ...(languages && { languages }),
    }),
    []
  );
  // Query
  const [fetch, query] = useLazyQuery(Tags.READ, {
    fetchPolicy,
    nextFetchPolicy,
    notifyOnNetworkStatusChange: true,
    variables: getVariables({ input }),
  });
  const { called, error, loading, refetch } = query;
  // Memo
  const data = useMemo(() => {
    return getData(query);
  }, [query]);
  // Callbacks
  const performFetch = useCallback(
    async (options = {}) => {
      const variables = getVariables({
        input: options.input ?? input,
        resources: options.resources ?? resources,
        languages: options.languages ?? languages,
      });
      log.info("⚪", "ReadLazy:Fetch", { variables });
      try {
        const result = await fetch({ variables });
        return getData(result);
      } catch (error) {
        if (options.throwOnError) {
          throw error;
        }
      }
    },
    [log, getVariables, fetch, input, resources, languages]
  );
  const performRefetch = useCallback(
    async (options = {}) => {
      if (refetch) {
        const variables = getVariables({ input, resources, languages });
        log.info("⚪", "ReadLazy:Refetch", { variables });
        try {
          const result = refetch({ variables });
          return getData(result);
        } catch (error) {
          if (options.throwOnError) {
            throw error;
          }
        }
      }
    },
    [log, getVariables, refetch, input, resources, languages]
  );
  // Effects
  useEffect(() => {
    if (!disableAutoRefresh) {
      refreshCount.current += 1;
    }
  }, [disableAutoRefresh, refreshToken]);
  useEffect(() => {
    if (!disableAutoRefresh && refreshCount.current > 0) {
      performRefetch().catch(noop);
    }
  }, [disableAutoRefresh, refreshToken, performRefetch]);
  // Render
  return {
    called,
    data,
    error,
    loading,
    fetch: performFetch,
    refetch: performRefetch,
  };
};
