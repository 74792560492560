// React
import React from "react";
// Framework
import { forwardType, External, Externals } from "@mefisto/web";
// Components
import AppStoreBadge from "./assets/appstore-badge.png";
import DashedLine from "./assets/dashed-line.png";
import DashedLineShort from "./assets/dashed-line-short.png";
import DownloadsMov1x from "./assets/downloads@1x.mov";
import DownloadsWebm1x from "./assets/downloads@1x.webm";
import DownloadsMov2x from "./assets/downloads@2x.mov";
import DownloadsWebm2x from "./assets/downloads@2x.webm";
import DownloadsPoster from "./assets/downloads-poster.png";
import FaqsMov1x from "./assets/faqs@1x.mov";
import FaqsWebm1x from "./assets/faqs@1x.webm";
import FaqsMov2x from "./assets/faqs@2x.mov";
import FaqsWebm2x from "./assets/faqs@2x.webm";
import FaqsPoster from "./assets/faqs-poster.png";
import GooglePlayIcon from "./assets/google-play-badge.png";
import JammTitle from "./assets/jamm-title.png";
import LandingMov1x from "./assets/landing@1x.mov";
import LandingMov2x from "./assets/landing@2x.mov";
import LandingWebm1x from "./assets/landing@1x.webm";
import LandingWebm2x from "./assets/landing@2x.webm";
import LandingPoster from "./assets/landing-poster.png";
import MaintenanceConsumer from "./assets/maintenance-consumer.png";
import MaintenanceProvider from "./assets/maintenance-provider.png";
import MaintenanceShowCaseMov from "./assets/maintenance-showcase.mov";
import MaintenanceShowCaseWebm from "./assets/maintenance-showcase.webm";
import MaintenanceShowCasePoster from "./assets/maintenance-showcase-poster.png";
import OrderingConsumer from "./assets/ordering-consumer.png";
import OrderingProvider from "./assets/ordering-provider.png";
import OrderingShowCaseMov from "./assets/ordering-showcase.mov";
import OrderingShowCaseWebm from "./assets/ordering-showcase.webm";
import OrderingShowCasePoster from "./assets/ordering-showcase-poster.png";
import PortalShowCaseMov from "./assets/portal-showcase.mov";
import PortalShowCaseWebm from "./assets/portal-showcase.webm";
import PortalShowCasePoster from "./assets/portal-showcase-poster.png";
import PricingMov1x from "./assets/pricing@1x.mov";
import PricingWebm1x from "./assets/pricing@1x.webm";
import PricingMov2x from "./assets/pricing@2x.mov";
import PricingWebm2x from "./assets/pricing@2x.webm";
import PricingPoster from "./assets/pricing-poster.png";
import Splashscreen from "./assets/splashscreen.png";
import StorageShowCaseMov from "./assets/storage-showcase.mov";
import StorageShowCaseWebm from "./assets/storage-showcase.webm";
import StorageShowCasePoster from "./assets/storage-showcase-poster.png";
import Step1Mov1x from "./assets/step1@1x.mov";
import Step1Webm1x from "./assets/step1@1x.webm";
import Step1Mov2x from "./assets/step1@2x.mov";
import Step1Webm2x from "./assets/step1@2x.webm";
import Step1Poster from "./assets/step1-poster.png";
import Step2Mov1x from "./assets/step2@1x.mov";
import Step2Webm1x from "./assets/step2@1x.webm";
import Step2Mov2x from "./assets/step2@2x.mov";
import Step2Webm2x from "./assets/step2@2x.webm";
import Step2Poster from "./assets/step2-poster.png";
import Step3Mov1x from "./assets/step3@1x.mov";
import Step3Webm1x from "./assets/step3@1x.webm";
import Step3Mov2x from "./assets/step3@2x.mov";
import Step3Webm2x from "./assets/step3@2x.webm";
import Step3Poster from "./assets/step3-poster.png";
import Step4Mov1x from "./assets/step4@1x.mov";
import Step4Webm1x from "./assets/step4@1x.webm";
import Step4Mov2x from "./assets/step4@2x.mov";
import Step4Webm2x from "./assets/step4@2x.webm";
import Step4Poster from "./assets/step4-poster.png";
import StraightLine from "./assets/straight-line.png";
import TooltipsAbner from "./assets/tooltips-abner.png";
import TooltipsMarcel from "./assets/tooltips-marcel.png";
import TooltipsMaya from "./assets/tooltips-maya.png";

export default forwardType(Externals, () => (
  <Externals>
    <External name="APP_STORE_BADGE" path={AppStoreBadge} />
    <External name="DASHED_LINE" path={DashedLine} />
    <External name="DASHED_LINE_SHORT" path={DashedLineShort} />
    <External
      name="DOWNLOADS"
      path={[
        { path: DownloadsMov2x, extension: "mov", devicePixelRatio: 2 },
        { path: DownloadsMov1x, extension: "mov", devicePixelRatio: 1 },
        { path: DownloadsWebm2x, extension: "webm", devicePixelRatio: 2 },
        { path: DownloadsWebm1x, extension: "webm", devicePixelRatio: 1 },
      ]}
    />
    <External name="DOWNLOADS_POSTER" path={DownloadsPoster} />
    <External
      name="FAQS"
      path={[
        { path: FaqsMov2x, extension: "mov", devicePixelRatio: 2 },
        { path: FaqsMov1x, extension: "mov", devicePixelRatio: 1 },
        { path: FaqsWebm2x, extension: "webm", devicePixelRatio: 2 },
        { path: FaqsWebm1x, extension: "webm", devicePixelRatio: 1 },
      ]}
    />
    <External name="FAQS_POSTER" path={FaqsPoster} />
    <External name="GOOGLE_PLAY_BADGE" path={GooglePlayIcon} />
    <External name="JAMM_TITLE" path={JammTitle} />
    <External
      name="LANDING"
      path={[
        { path: LandingMov2x, extension: "mov", devicePixelRatio: 2 },
        { path: LandingMov1x, extension: "mov", devicePixelRatio: 1 },
        { path: LandingWebm2x, extension: "webm", devicePixelRatio: 2 },
        { path: LandingWebm1x, extension: "webm", devicePixelRatio: 1 },
      ]}
    />
    <External name="LANDING_POSTER" path={LandingPoster} />
    <External name="MAINTENANCE_CONSUMER" path={MaintenanceConsumer} />
    <External name="MAINTENANCE_PROVIDER" path={MaintenanceProvider} />
    <External
      name="MAINTENANCE_SHOWCASE"
      path={[
        { path: MaintenanceShowCaseMov, extension: "mov" },
        { path: MaintenanceShowCaseWebm, extension: "webm" },
      ]}
    />
    <External
      name="MAINTENANCE_SHOWCASE_POSTER"
      path={MaintenanceShowCasePoster}
    />
    <External
      name="ORDERING_SHOWCASE"
      path={[
        { path: OrderingShowCaseMov, extension: "mov" },
        { path: OrderingShowCaseWebm, extension: "webm" },
      ]}
    />
    <External name="ORDERING_SHOWCASE_POSTER" path={OrderingShowCasePoster} />
    <External name="ORDERING_CONSUMER" path={OrderingConsumer} />
    <External name="ORDERING_PROVIDER" path={OrderingProvider} />
    <External
      name="PORTAL_SHOWCASE"
      path={[
        { path: PortalShowCaseMov, extension: "mov" },
        { path: PortalShowCaseWebm, extension: "webm" },
      ]}
    />
    <External name="PORTAL_SHOWCASE_POSTER" path={PortalShowCasePoster} />
    <External
      name="PRICING"
      path={[
        { path: PricingMov2x, extension: "mov", devicePixelRatio: 2 },
        { path: PricingMov1x, extension: "mov", devicePixelRatio: 1 },
        { path: PricingWebm2x, extension: "webm", devicePixelRatio: 2 },
        { path: PricingWebm1x, extension: "webm", devicePixelRatio: 1 },
      ]}
    />
    <External name="PRICING_POSTER" path={PricingPoster} />
    <External name="SPLASH_SCREEN" path={Splashscreen} />
    <External
      name="STEP1"
      path={[
        { path: Step1Mov2x, extension: "mov", devicePixelRatio: 2 },
        { path: Step1Mov1x, extension: "mov", devicePixelRatio: 1 },
        { path: Step1Webm2x, extension: "webm", devicePixelRatio: 2 },
        { path: Step1Webm1x, extension: "webm", devicePixelRatio: 1 },
      ]}
    />
    <External name="STEP1_POSTER" path={Step1Poster} />
    <External
      name="STEP2"
      path={[
        { path: Step2Mov2x, extension: "mov", devicePixelRatio: 2 },
        { path: Step2Mov1x, extension: "mov", devicePixelRatio: 1 },
        { path: Step2Webm2x, extension: "webm", devicePixelRatio: 2 },
        { path: Step2Webm1x, extension: "webm", devicePixelRatio: 1 },
      ]}
    />
    <External name="STEP2_POSTER" path={Step2Poster} />
    <External
      name="STEP3"
      path={[
        { path: Step3Mov2x, extension: "mov", devicePixelRatio: 2 },
        { path: Step3Mov1x, extension: "mov", devicePixelRatio: 1 },
        { path: Step3Webm2x, extension: "webm", devicePixelRatio: 2 },
        { path: Step3Webm1x, extension: "webm", devicePixelRatio: 1 },
      ]}
    />
    <External name="STEP3_POSTER" path={Step3Poster} />
    <External
      name="STEP4"
      path={[
        { path: Step4Mov2x, extension: "mov", devicePixelRatio: 2 },
        { path: Step4Mov1x, extension: "mov", devicePixelRatio: 1 },
        { path: Step4Webm2x, extension: "webm", devicePixelRatio: 2 },
        { path: Step4Webm1x, extension: "webm", devicePixelRatio: 1 },
      ]}
    />
    <External name="STEP4_POSTER" path={Step4Poster} />
    <External
      name="STORAGE_SHOWCASE"
      path={[
        { path: StorageShowCaseMov, extension: "mov" },
        { path: StorageShowCaseWebm, extension: "webm" },
      ]}
    />
    <External name="STORAGE_SHOWCASE_POSTER" path={StorageShowCasePoster} />
    <External name="STRAIGHT_LINE" path={StraightLine} />
    <External name="TOOLTIPS_ABNER" path={TooltipsAbner} />
    <External name="TOOLTIPS_MARCEL" path={TooltipsMarcel} />
    <External name="TOOLTIPS_MAYA" path={TooltipsMaya} />
  </Externals>
));
