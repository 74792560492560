// React
import React from "react";
// Framework
import {
  Portal,
  Firebase,
  Navigation,
  Localization,
  Language,
  Interceptors,
  LocalizationInterceptor,
} from "@mefisto/web";
// Fonts
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
// Apps
import AdminApp from "@apps/admin";
import AuthApp from "@apps/auth";
import MaintenanceApp from "@apps/maintenance";
import OrderingApp from "@apps/ordering";
import PortalApp from "@apps/portal";
import PresentationApp from "@apps/presentation";
import StorageApp from "@apps/storage";
// Plugins
import { AppPortalLayoutPlugin } from "@mefisto/plugin-portal/components";
// Components
import { Externals } from "externals";
import { Themes } from "themes";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default () => (
  <Portal>
    <Externals />
    <Themes />
    <Firebase modules={["analytics", "auth", "database", "messaging"]} />
    <Navigation
      routes={{
        home: "/",
        authentication: "/auth",
        unauthenticated: "/portal/dashboard",
        dashboard: "/portal/dashboard",
        profile: "/portal/profile",
        organization: "/portal/organization",
        organizationCreate: "/portal/organization/get-started",
        accountCreate: "/auth/sign-up",
        location: "/portal/locations/:location",
        memberships: "/portal/memberships",
        connections: "/portal/connections",
        notVerified: "/auth/verify",
        notAuthenticated: "/auth/sign-in",
        notAdmin: "/portal",
        notOrganization: "/portal/organization/get-started",
      }}
    />
    <Localization
      languages={[
        <Language locale="en" title="English" />,
        <Language locale="es" title="Español" />,
      ]}
    />
    <Interceptors interceptors={[<LocalizationInterceptor />]} />
    {/* Apps */}
    <AdminApp />
    <AuthApp />
    <MaintenanceApp />
    <OrderingApp />
    <PortalApp />
    <PresentationApp />
    <StorageApp />
    {/* Plugins */}
    <AppPortalLayoutPlugin position="leading" />
  </Portal>
);
