// React
import React from "react";
// Framework
import { colors, emphasize, fade, Theme } from "@mefisto/web";

export default () => {
  // Render
  return (
    <Theme
      name="light"
      title="Light"
      theme={{
        typography: {
          h5: {
            fontWeight: 500,
          },
          subtitle2: {
            fontSize: "0.915rem",
            letterSpacing: "0.00914em",
          },
          caption: {
            fontWeight: 500,
            lineHeight: 1.2,
          },
        },
        palette: {
          primary: {
            dark: "#df14c7",
            main: "#ce4fbf",
            light: "#c68cc1",
            contrastText: "#ffffff",
          },
          secondary: {
            dark: "#0d1315",
            main: "#263238",
            light: "#797979",
            contrastText: "#ffffff",
          },
          text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[400],
          },
          grey: {
            ...colors.grey,
            100: "#f9f9f9",
          },
          action: {
            active: "#435960",
            selected: "rgba(0, 0, 0, 0.04)",
            hoverOpacity: 0.05,
          },
          error: colors.red,
          divider: "#e8e8e8",
          background: {
            default: "#fff",
            underlying:
              "linear-gradient(261deg, rgb(247 243 241) 0%, rgb(242, 247, 249) 50%, rgb(247 244 250) 90%)",
          },
        },
        shadows: [
          "none",
          "rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px",
          "rgb(95 116 141 / 3%) 0px 5px 10px -3px, rgb(95 116 141 / 4%) 0px 8px 18px 1px, rgb(95 116 141 / 8%) 0px 3px 33px 2px",
        ],
      }}
      overrides={(theme) => ({
        MuiCssBaseline: {
          "@global": {
            body: {
              background:
                "linear-gradient(261deg, rgb(247 243 241) 0%, rgb(242, 247, 249) 50%, rgb(247 244 250) 90%)",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
            },
          },
        },
        MuiPopover: {
          paper: {
            boxShadow: theme.shadows[2],
          },
        },
        MuiTouchRipple: {
          /**
           * Make the ripple effect less visible
           */
          rippleVisible: {
            opacity: 0.05,
          },
          "@keyframes enter": {
            "100%": {
              transform: "scale(1)",
              opacity: 0.05,
            },
          },
        },
        MuiAlert: {
          root: {
            borderRadius: theme.radius.normal,
          },
        },
        MuiAutocomplete: {
          paper: {
            boxShadow: "0 0 12px 7px rgb(0 0 0 / 0.05)",
            borderRadius: theme.radius.small,
          },
        },
        MuiPaper: {
          /**
           * Don't round paper corners
           */
          rounded: {
            borderRadius: theme.radius.none,
          },
        },
        MuiCardActionArea: {
          root: {
            /**
             * Make the card action area focus state less visible
             */
            "&$focusVisible $focusHighlight": {
              opacity: theme.palette.action.hoverOpacity,
            },
          },
        },
        MuiFormLabel: {
          root: {
            color: theme.palette.text.primary,
            fontWeight: 500,
            fontSize: "1.02rem",
            letterSpacing: "0.02em",
          },
        },
        MuiStepIcon: {
          root: {
            color: theme.palette.grey[400],
          },
        },
        MuiStepConnector: {
          line: {
            borderColor: theme.palette.grey[300],
          },
        },
        MuiStepLabel: {
          label: {
            ...theme.typography.subtitle2,
            color: theme.palette.grey[500],
          },
        },
        MuiSkeleton: {
          root: {
            backgroundColor: theme.palette.grey[200],
          },
        },
        MuiButton: {
          label: {
            /**
             * Don't transform button label
             */
            textTransform: "none",
          },
          contained: {
            /**
             * No shadow around contained buttons
             */
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
            "&$focusVisible": {
              boxShadow: "none",
            },
            "&:active": {
              boxShadow: "none",
            },
          },
          outlined: {
            textTransform: "capitalize",
            borderWidth: 2,
            borderColor: theme.palette.text.secondary,
            "&:hover": {
              borderWidth: 2,
            },
            paddingTop: 4,
            paddingBottom: 4,
            "&$disabled": {
              border: `2px solid ${theme.palette.grey[300]}`,
            },
          },
          outlinedPrimary: {
            borderWidth: 2,
            "&:hover": {
              borderWidth: 2,
            },
            paddingTop: 4,
            paddingBottom: 4,
            "&$disabled": {
              border: `2px solid ${theme.palette.grey[300]}`,
            },
          },
          outlinedSecondary: {
            borderWidth: 2,
            "&:hover": {
              borderWidth: 2,
            },
            paddingTop: 4,
            paddingBottom: 4,
            "&$disabled": {
              border: `2px solid ${theme.palette.grey[300]}`,
            },
          },
        },
        MuiIconButton: {
          root: {
            padding: theme.spacing(1),
          },
        },
        MuiBadge: {
          colorError: {
            backgroundColor: "#e45366",
          },
          badge: {
            boxShadow: "1px 2px 4px rgba(0,0,0,.2)",
          },
        },
        MuiChip: {
          sizeSmall: {
            height: theme.spacing(2.5),
          },
          labelSmall: {
            ...theme.typography.subtitle2,
            fontSize: theme.typography.pxToRem(12),
          },
          clickable: {
            "&:hover, &:focus": {
              backgroundColor: emphasize(theme.palette.grey[300], 0.01),
            },
            "&:active": {
              boxShadow: "none",
              backgroundColor: emphasize(theme.palette.grey[300], 0.01),
            },
          },
          deletable: {
            "&:focus": {
              backgroundColor: emphasize(theme.palette.grey[300], 0.01),
            },
          },
        },
        MuiFormControl: {
          root: {
            outline: 0,
          },
        },
        MuiOutlinedInput: {
          notchedOutline: {
            borderColor: theme.palette.divider,
            borderWidth: 2,
          },
        },
        MuiSnackbarContent: {
          root: {
            // Make the long text be inline with close button
            flexWrap: "nowrap",
          },
        },
        MuiTooltip: {
          tooltip: {
            borderRadius: theme.radius.none,
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.subtitle2.fontWeight,
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
          },
        },
        MuiTab: {
          wrapper: {
            textTransform: "initial",
          },
          textColorSecondary: {
            color: theme.palette.action.active,
          },
        },
        MuiListItem: {
          button: {
            "&:hover": {
              // Make the selection color lighter
              backgroundColor: fade(theme.palette.grey[500], 0.08),
            },
          },
        },
        MuiListItemText: {
          root: {
            overflowWrap: "break-word",
          },
        },
        MuiMenuItem: {
          root: {
            "&$selected": {
              // Make the selection color lighter
              backgroundColor: fade(theme.palette.grey[500], 0.04),
            },
          },
        },
        MuiTableCell: {
          root: {
            borderBottom: "none",
            padding: theme.spacing(1, 2, 1, 3),
          },
        },
        MuiTableRow: {
          root: {
            "&$selected": {
              backgroundColor: fade(theme.palette.grey[500], 0.06),
            },
            "&$hover:hover": {
              backgroundColor: fade(theme.palette.grey[500], 0.04),
            },
          },
        },
        MuiDivider: {
          root: {
            height: 2,
          },
        },
        MuiDialog: {
          paper: {
            borderRadius: theme.radius.large,
            boxShadow: "0px 13px 40px 10px rgba(0, 0, 0, 0.07)",
          },
        },
        MuiDialogTitle: {
          root: {
            paddingBottom: theme.spacing(1),
          },
        },
        MuiDialogActions: {
          root: {
            margin: 0,
            padding: theme.spacing(2, 3, 3),
          },
        },
        MuiSelect: {
          icon: {
            color: "inherit",
          },
        },
        MuiInput: {
          root: {
            ...theme.typography.subtitle2,
            lineHeight: 1,
          },
          underline: {
            "&:before": {
              borderBottomColor: "inherit",
              borderBottomWidth: 2,
            },
            "&:after": {
              borderBottomColor: "inherit",
              borderBottomWidth: 3,
            },
            "&:focus": {
              borderBottomColor: "inherit",
              borderBottomWidth: 3,
            },
            "&:hover:before": {
              borderBottomColor: "inherit !important",
              borderBottomWidth: "3px !important",
            },
          },
        },
        MuiInputBase: {
          input: {
            paddingBottom: 6,
          },
        },
      })}
    />
  );
};
