// React
import React from "react";
// Helpers
import { map, range } from "@mefisto/utils";
// Framework
import { makeStyles, Grid, Box, Skeleton, ErrorPlaceholder } from "ui";
// Components
import ZonedCalendar from "./components/ZonedCalendar";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body2,
  },
  skeletons: {
    height: `calc(100% - ${theme.spacing(3)}px)`,
  },
  skeleton: {
    background: theme.palette.grey[100],
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Calendar = ({ loading, error, onRefresh, ...rest }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Box height="100%" position="relative">
      {loading ? (
        <Box pt={2} height="100%">
          <Grid container spacing={1}>
            {map(range(6), (index) => (
              <Grid key={index} item xs={2}>
                <Skeleton animation={false} className={classes.skeleton} />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={1} className={classes.skeletons}>
            {map(range(30), (index) => (
              <Grid key={index} item xs={2}>
                <Skeleton
                  animation={false}
                  variant="rect"
                  height="100%"
                  className={classes.skeleton}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <>
          {error ? (
            <ErrorPlaceholder position="center" onAction={onRefresh} />
          ) : (
            <ZonedCalendar toolbar={false} {...rest} />
          )}
        </>
      )}
    </Box>
  );
};

export default Calendar;
