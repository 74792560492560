// React
import React from "react";
// Framework
import { usePortal, forwardType, App } from "@mefisto/web";
// Icon
import { PortalIcon } from "icons";
// Loader
import { Loader } from "loader";
// Navigation
import { paths as portalPaths } from "@mefisto/plugin-portal/navigation";
import { paths as billingPaths } from "@plugins/billing";
// Permissions
import { portal as permissions } from "@mefisto/plugin-portal/permissions";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default forwardType(App, () => {
  // Framework
  const { config } = usePortal();
  const { appsAccountEnabled, appsAccountUrl } = config.values;
  // Render
  return (
    <App
      visible
      name="PORTAL"
      type="USER"
      enabled={appsAccountEnabled}
      title="Portal"
      color="#00b3e3"
      priority={2}
      path={appsAccountUrl}
      icon={<PortalIcon />}
      loader={<Loader />}
      logo={{
        title: "Portal",
      }}
      theme={{
        palette: {
          primary: {
            dark: "#111b1f",
            main: "#273542",
            light: "#8d8d8d",
          },
          secondary: {
            dark: "#df14c7",
            main: "#ce4fbf",
            light: "#c68cc1",
          },
        },
      }}
      navigation={{
        basename: "portal",
        routes: {
          default: "/dashboard",
          notAuthorized: "/organization/get-started",
        },
        paths: {
          ...portalPaths,
          ...billingPaths,
          organization: "/organization",
          dashboard: "/dashboard",
        },
      }}
      resource={{
        source: "ui",
      }}
      permissions={permissions()}
    />
  );
});
