// React
import React from "react";
// Helpers
import { capitalize, get } from "@mefisto/utils";
// Framework
import { Box, fade } from "ui/components";
import { useTheme } from "theme";
import {
  CheckCircleRounded as SuccessIcon,
  WarningRounded as WarningIcon,
  InfoRounded as ErrorIcon,
  InfoRounded as InfoIcon,
} from "icon/material";

export const allClasses = {
  mui: {
    root: {},
    anchorOriginTopCenter: {},
    anchorOriginBottomCenter: {},
    anchorOriginTopRight: {},
    anchorOriginBottomRight: {},
    anchorOriginTopLeft: {},
    anchorOriginBottomLeft: {},
  },
  container: {
    containerAnchorOriginTopCenter: {},
    containerAnchorOriginBottomCenter: {},
    containerAnchorOriginTopRight: {},
    containerAnchorOriginBottomRight: {},
    containerAnchorOriginTopLeft: {},
    containerAnchorOriginBottomLeft: {},
  },
};

const Icon = ({ icon, color }) => {
  // Theme
  const theme = useTheme();
  // Render
  return (
    <Box
      display="flex"
      p={1}
      mr={2}
      borderRadius={theme.radius.rounded}
      bgcolor={fade(get(theme.palette, color, color), 0.15)}
      color={color}
    >
      {icon}
    </Box>
  );
};

export const defaultIconVariant = {
  info: <Icon color="info.main" icon={<InfoIcon />} />,
  warning: <Icon color="warning.main" icon={<WarningIcon />} />,
  success: <Icon color="success.main" icon={<SuccessIcon />} />,
  error: <Icon color="error.main" icon={<ErrorIcon />} />,
};

export const SNACKBAR_INDENTS = {
  view: { default: 20, dense: 4 },
  snackbar: { default: 6, dense: 2 },
};

export const originKeyExtractor = (anchor) => {
  return `${capitalize(anchor.vertical)}${capitalize(anchor.horizontal)}`;
};

export const REASONS = {
  CLICKAWAY: "clickaway",
  MAXSNACK: "maxsnack",
};
