// React
import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { map, compact } from "@mefisto/utils";
// Framework
import { Calendar } from "time";
import { useEntityListLazy } from "model/hooks";
// Components
import { EntityPropType } from "../../utils";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelCalendar = forwardRef(
  (
    {
      entity: Entity,
      relation,
      input,
      resources,
      languages,
      pagination,
      fetchPolicy,
      nextFetchPolicy,
      tags,
      defaultDate,
      disablePast,
      getItem,
      selectable,
      onSelect,
      onAdd,
    },
    ref
  ) => {
    // Model
    const { error, data: list, fetch, loading } = useEntityListLazy(Entity, {
      input,
      resources,
      languages,
      relation,
      pagination,
      fetchPolicy,
      nextFetchPolicy,
      tags,
    });
    // Ref
    useImperativeHandle(ref, () => ({
      refresh() {
        fetch();
      },
    }));
    // Memo
    const data = useMemo(() => {
      return compact(
        map(list.data, (data) => {
          const event = getItem(data);
          if (event) {
            return { event, data };
          }
        })
      );
    }, [list.data, getItem]);
    const events = useMemo(() => {
      return map(data, ({ event }, index) => ({
        ...event,
        index,
      }));
    }, [data]);
    // Handlers
    const handleSelectEvent = useCallback(
      (event) => {
        onSelect && onSelect(data[event.index].data, event);
      },
      [onSelect, data]
    );
    const handleRefresh = useCallback(() => {
      fetch();
    }, [fetch]);
    // Effects
    useEffect(() => {
      fetch();
    }, [fetch]);
    // Render
    return (
      <Calendar
        disablePast={disablePast}
        error={error}
        loading={loading}
        events={events}
        defaultDate={defaultDate}
        selectable={selectable}
        onSelectSlot={onAdd}
        onSelectEvent={handleSelectEvent}
        onRefresh={handleRefresh}
      />
    );
  }
);

ModelCalendar.propTypes = {
  /**
   * Model entity used for the feed
   */
  entity: EntityPropType,
  /**
   * Entity relation
   */
  relation: PropTypes.string,
  /**
   * Input data
   */
  input: PropTypes.object,
  /**
   * Resources data
   */
  resources: PropTypes.object,
  /**
   * List of requested languages
   */
  languages: PropTypes.array,
  /**
   * Pagination props
   */
  pagination: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  /**
   * Fetch policy of the list request
   */
  fetchPolicy: PropTypes.string,
  /**
   * Next fetch policy of the list request
   */
  nextFetchPolicy: PropTypes.string,
  /**
   * Custom tags used to list the data
   */
  tags: PropTypes.any,
  /**
   * Current default date
   */
  defaultDate: PropTypes.any,
  /**
   * Set to `true` to disable dates in the past
   */
  disablePast: PropTypes.any,
  /**
   * If set to `true` slot can be selected by user
   */
  selectable: PropTypes.any,
  /**
   * Called when user selects one of dates
   */
  onAdd: PropTypes.func,
  /**
   * Called when user selects one of events
   */
  onSelect: PropTypes.func,
};

export default ModelCalendar;
