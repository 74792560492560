// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { map, isEmpty, slice, first, get } from "@mefisto/utils";
// Framework
import { Dropzone, FileChip } from "storage";
import { Grid, Box, Typography } from "ui";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  label,
  form,
  field,
  multiple,
  accept,
  height,
  helperText,
}) => {
  const { name, value } = field;
  const { touched, errors } = form;
  const fieldError = get(errors, name);
  const showError = get(touched, name) && !!fieldError;
  // Handlers
  const handleDrop = (files) => {
    const fieldValue = multiple ? [...value, ...files] : first(files);
    form.setFieldValue(name, fieldValue);
  };
  const handleClear = (index) => {
    form.setFieldValue(name, [
      ...slice(value, 0, index),
      ...slice(value, index + 1),
    ]);
  };
  // Render
  return (
    <Grid container direction="column" spacing={1}>
      {!isEmpty(value) && (
        <Grid item>
          <Grid container spacing={1}>
            {map(multiple ? value : [value], (file, index) => (
              <Grid item key={index}>
                <FileChip
                  label={file.name}
                  type={file.type ?? file.contentType}
                  onDelete={() => handleClear(index)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Dropzone
          multiple={multiple}
          height={height}
          label={label}
          accept={accept}
          error={showError}
          onDrop={handleDrop}
        />
        <Box ml={2}>
          <Typography
            variant="caption"
            color={showError ? "error" : "textSecondary"}
          >
            {showError ? fieldError : helperText}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const FormFileField = (props) => <FormField component={Component} {...props} />;

FormFileField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  accept: PropTypes.object,
  multiple: PropTypes.bool,
  height: PropTypes.number,
};

export default FormFileField;
