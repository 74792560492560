// React
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { map } from "@mefisto/utils";
// Framework
import { usePortal } from "stack";
import { classnames, makeStyles } from "ui";
import { useInjectStyle } from "hooks";
// Components
import { Calendar, momentLocalizer } from "react-big-calendar";
import { accessor } from "react-big-calendar/lib/utils/accessors";
import "react-big-calendar/lib/css/react-big-calendar.css";
import overrides from "./styles/overrides.css";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  disabled: {
    background: "transparent !important",
  },
  selectable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ZonedCalendar = ({
  startAccessor = "start",
  endAccessor = "end",
  defaultDate,
  selectable,
  disablePast,
  onSelectSlot,
  onSelectEvent,
  onEventDrop,
  ...rest
}) => {
  // Styles
  useInjectStyle("calendar", overrides);
  const classes = useStyles();
  // Framework
  const { time } = usePortal();
  // Memo
  const localizer = useMemo(() => {
    return momentLocalizer(time.localizer());
  }, [time]);
  // Callbacks
  const toLocalDate = useCallback((date) => {
    // Map to local time, so it can be displayed by the calendar
    // since the Big Calendar shows everything in the local time.
    return date.local(true);
  }, []);
  const toTimezonedDate = useCallback(
    (date) => {
      // Map back to timezoned time
      return time.fromLocalTimestamp(date, {
        clockTime: true,
      });
    },
    [time]
  );
  // Handlers
  const handleStartAccessor = useCallback(
    (event) => {
      const start = accessor(event, startAccessor);
      return toLocalDate(start);
    },
    [startAccessor, toLocalDate]
  );
  const handleEndAccessor = useCallback(
    (event) => {
      const end = accessor(event, endAccessor);
      return toLocalDate(end);
    },
    [endAccessor, toLocalDate]
  );
  const handleEventDrop = useCallback(
    ({ event, start, end }) => {
      onEventDrop({
        event,
        start: toTimezonedDate(start),
        end: toTimezonedDate(end),
      });
    },
    [toTimezonedDate, onEventDrop]
  );
  const handleSelectEvent = useCallback(
    (...props) => {
      onSelectEvent(...props);
    },
    [onSelectEvent]
  );
  const handleSelectSlot = useCallback(
    ({ start, end, slots, ...props }) => {
      const timezonedDate = toTimezonedDate(start);
      // Check if selection is not in the past
      if (disablePast && time.isYesterdayOrBefore(timezonedDate)) {
        return;
      }
      // Check if selection is within the month
      if (!toLocalDate(defaultDate).isSame(timezonedDate, "month")) {
        return;
      }
      onSelectSlot({
        ...props,
        start: toTimezonedDate(start),
        end: toTimezonedDate(end),
        slots: map(slots, (date) => toTimezonedDate(date)),
      });
    },
    [time, defaultDate, disablePast, onSelectSlot, toLocalDate, toTimezonedDate]
  );
  // Render
  return (
    <Calendar
      popup
      localizer={localizer}
      selectable={selectable}
      views={["month"]}
      defaultView="month"
      getNow={() => toLocalDate(time.today())}
      defaultDate={toLocalDate(defaultDate).toDate()}
      startAccessor={handleStartAccessor}
      endAccessor={handleEndAccessor}
      eventPropGetter={({ color }) => ({
        style: { backgroundColor: color },
      })}
      dayPropGetter={(day) => {
        const disabled =
          disablePast && time.isYesterdayOrBefore(toTimezonedDate(day));
        const withinMonth = toLocalDate(defaultDate).isSame(
          toTimezonedDate(day),
          "month"
        );
        return {
          className: classnames(
            disabled && classes.disabled,
            !disabled && withinMonth && selectable && classes.selectable
          ),
        };
      }}
      onEventDrop={onEventDrop && handleEventDrop}
      onSelectEvent={onSelectEvent && handleSelectEvent}
      onSelectSlot={onSelectSlot && handleSelectSlot}
      {...rest}
    />
  );
};

ZonedCalendar.propTypes = {
  defaultDate: PropTypes.object.isRequired,
  events: PropTypes.array,
  selectable: PropTypes.bool,
  disablePast: PropTypes.bool,
  onSelectSlot: PropTypes.func,
  onEventDrop: PropTypes.func,
};

export default ZonedCalendar;
