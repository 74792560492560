// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { HelmetProvider as Provider } from "react-helmet-async";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HelmetProvider = ({ options, children }) => (
  <Provider>
    {options}
    {children}
  </Provider>
);

HelmetProvider.propTypes = {
  options: PropTypes.element,
  children: PropTypes.node,
};

export { HelmetProvider };
