// Helpers
import {
  reduce,
  join,
  isEmpty,
  startsWith,
  endsWith,
  trim,
  replace,
  isNumber,
} from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

export class Format extends StackDependency {
  #countryMap;

  async load() {
    const { countryMap } = await import("./model/data");
    this.#countryMap = countryMap;
  }

  /**
   * Replaces content in `{}` with the given params
   * @param string
   * @param params
   * @returns {string}
   */
  #replace = (string = "", params = {}) => {
    return replace(
      string,
      /{(\w+)}/g,
      (key, placeholder) => params[placeholder] ?? ""
    );
  };

  /**
   * Returns list of countries and their format metadata
   * @returns {[]}
   */
  get countries() {
    return this.#countryMap;
  }

  /**
   * Formats address
   * @param address {object} Address object
   * @param inline {boolean} Set to `true` if the result should be a string
   * @return {[string]|string}
   */
  address(address, { inline } = {}) {
    const country = this.#countryMap[address.country];
    const result = reduce(
      country?.address?.format,
      (result, line) => {
        let replaced = trim(
          this.#replace(line, {
            ...address,
            // Amend country name (the address has only code)
            country: country?.label,
          })
        );
        if (startsWith(replaced, ",")) {
          replaced = replaced.substring(1);
        }
        if (endsWith(replaced, ",")) {
          replaced = replaced.slice(0, -1);
        }
        if (!isEmpty(replaced)) {
          result.push(replaced);
        }
        return result;
      },
      []
    );
    return inline ? join(result, ", ") : result;
  }

  /**
   * Formats price based on currency and locale.
   * @param value {number} Price to be formatted
   * @param currency {string} Price currency
   * @param options {object=} Pricing options
   * @returns {string}
   */
  price(value, currency, options) {
    if (isNumber(value) === false) {
      return "";
    }
    // First, get global locale, then locale for pricing, then locale
    // for the particular function call and default to localization locale.
    const locale =
      this.options.locale ??
      this.options.price?.locale ??
      options?.locale ??
      this.context.localization.locale;
    // Format using browser's Intl formatter
    const formatter = Intl.NumberFormat(locale, {
      ...this.options.price,
      ...options,
      style: "currency",
      currency,
    });
    return formatter.format(value / 100);
  }
}
