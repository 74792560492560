// React
import React from "react";
// Framework
import { usePortal } from "stack";
import { useUser, AuthState } from "authentication";
// Components
import Redirect from "../Redirect";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

/**
 * Routes to given component if the user is not verified.
 * Otherwise, redirects to a root scene.
 */
const RouteUnverified = ({ children }) => {
  // Framework
  const { navigation } = usePortal();
  const {
    user: {
      state,
      data: { emailVerified },
    },
  } = useUser();
  // State is still not determined, don't return the component
  if (state === AuthState.loading) {
    return null;
  }
  // Not authenticated
  if (state === AuthState.notAuthenticated) {
    return (
      <Redirect replace continueUrl to={navigation.routes.notAuthenticated} />
    );
  }
  // User is authenticated and verified, so he doesn't have
  // an access to unverified routes
  const isAuthenticated = state === AuthState.authenticated;
  const isVerified = emailVerified === true;
  if (isAuthenticated && isVerified) {
    const { default: route } = navigation.routes;
    const continueUrl = navigation.continueUrl;
    return <Redirect replace to={continueUrl ? continueUrl : route} />;
  }
  // Do not redirect
  return children;
};

export default RouteUnverified;
