// React
import React, {
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { compact, includes, first, size } from "@mefisto/utils";
// Framework
import { usePortal } from "stack/core";
import { useLocation } from "navigation";
import { Section, Spacer, Cloak, Grid, Box, Tabs } from "ui";
// Options
import SceneOptions from "./components/SceneOptions";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SceneTabs = ({
  context = "tabs",
  value,
  options,
  disableTabCloak,
  children,
}) => {
  // Framework
  const { navigation } = usePortal();
  const {
    hash: { tab },
  } = useLocation();
  // Memo
  const tabs = useMemo(() => {
    return compact(
      Children.map(
        children,
        (child) => isValidElement(child) && child.props.value
      )
    );
  }, [children]);
  const currentTab = useMemo(() => {
    return includes(tabs, tab) ? tab : first(tabs);
  }, [tab, tabs]);
  const enableCloak = useMemo(() => {
    return !disableTabCloak && size(tabs) === 1;
  }, [disableTabCloak, tabs]);
  // Callbacks
  const handleChange = useCallback(
    (event, tab) => {
      navigation.setHash({
        params: { tab },
      });
    },
    [navigation]
  );
  // Render
  return (
    <Section context={context} value={value}>
      <Box position="relative">
        <Box
          position="absolute"
          width="100%"
          height={2}
          bottom={0}
          bgcolor="grey.200"
        />
        <Grid container spacing={1}>
          <Grid item>
            <Cloak enabled={enableCloak}>
              <Tabs
                value={currentTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
              >
                {Children.map(
                  children,
                  (child) =>
                    isValidElement(child) &&
                    cloneElement(child, {
                      __display: {
                        render: "tabs",
                      },
                    })
                )}
              </Tabs>
            </Cloak>
          </Grid>
          <Spacer />
          {options && (
            <Grid item>
              <SceneOptions options={options} />
            </Grid>
          )}
        </Grid>
      </Box>
      {Children.map(
        children,
        (child) =>
          isValidElement(child) &&
          cloneElement(child, {
            __display: {
              render: "content",
              currentTab,
            },
          })
      )}
    </Section>
  );
};

SceneTabs.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.node,
  disableTabCloak: PropTypes.bool,
  children: PropTypes.node,
};

export default SceneTabs;
