// React
import React from "react";
// Framework
import { usePortal } from "stack";
import { useUser, AuthState } from "authentication";
// Components
import Redirect from "../Redirect";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const RouteVerified = ({ children }) => {
  // Framework
  const { navigation } = usePortal();
  const {
    user: {
      state,
      data: { emailVerified },
    },
  } = useUser();
  // Not authenticated
  if (state === AuthState.notAuthenticated) {
    return (
      <Redirect replace continueUrl to={navigation.routes.notAuthenticated} />
    );
  }
  // Not verified
  if (!emailVerified) {
    return <Redirect replace continueUrl to={navigation.routes.notVerified} />;
  }
  // Do not redirect
  return children;
};

export default RouteVerified;
