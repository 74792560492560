// React
import React, {
  Children,
  cloneElement,
  useState,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty, map, get } from "@mefisto/utils";
// Framework
import { classnames } from "ui/classnames";
import {
  makeStyles,
  fade,
  Box,
  Grid,
  Popover,
  MenuList,
  IconButton,
} from "ui/components";
import { MoreHorizRounded as MoreIcon } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  popover: {
    borderRadius: theme.radius.normal,
    marginTop: theme.spacing(0.5),
  },
  transparent: {
    zIndex: 1,
    color: theme.palette.common.white,
    backdropFilter: "blur(5px)",
    backgroundColor: fade(theme.palette.common.black, 0.5),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.6),
    },
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileOptions = ({ display = "permanent", options }) => {
  // Styles
  const classes = useStyles();
  // State
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // Handlers
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleButtonClick = useCallback((event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, []);
  // Memo
  const menuItems = useMemo(() => {
    return Children.map(options, (child) => {
      return get(child, "props.display") === "menu"
        ? cloneElement(child, {
            onClose: handleClose,
          })
        : null;
    });
  }, [options, handleClose]);
  const standaloneItems = useMemo(() => {
    return Children.map(options, (child) => {
      return get(child, "props.display") === "standalone"
        ? cloneElement(child, {
            onClose: handleClose,
          })
        : null;
    });
  }, [options, handleClose]);
  // Render
  return (
    <>
      {options && (
        <Box color="action.active">
          <Grid container spacing={1} wrap="nowrap" alignItems="center">
            {map(standaloneItems, (item, key) => (
              <Grid key={key} item>
                {item}
              </Grid>
            ))}
            {!isEmpty(menuItems) && (
              <Grid item>
                <IconButton
                  value="more"
                  color="inherit"
                  className={classnames({
                    [classes.transparent]: display === "transparent",
                  })}
                  onClick={handleButtonClick}
                >
                  <MoreIcon />
                </IconButton>
                <Popover
                  keepMounted
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  classes={{ paper: classes.popover }}
                  onClose={handleClose}
                >
                  <MenuList dense>{menuItems}</MenuList>
                </Popover>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

TileOptions.propTypes = {
  display: PropTypes.oneOf(["permanent", "transparent"]),
  options: PropTypes.arrayOf(PropTypes.node),
};

export default TileOptions;
