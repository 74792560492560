// React
import React, { Children } from "react";
import PropTypes from "prop-types";
// Helpers
import { compact } from "@mefisto/utils";
// Framework
import { Grid } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SceneOptions = ({ options = [] }) => (
  <>
    {Children.count(compact(options)) > 0 && (
      <Grid container wrap="nowrap" justify="flex-end" alignItems="center">
        {Children.map(compact(options), (option) => (
          <Grid item>{option}</Grid>
        ))}
      </Grid>
    )}
  </>
);

SceneOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.node),
};

export default SceneOptions;
