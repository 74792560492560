// React
import React, { isValidElement, cloneElement } from "react";
import PropTypes from "prop-types";
// Framework
import {
  classnames,
  makeStyles,
  Drawer,
  Grid,
  Hidden,
  Section,
  Spacer,
  Scrollable,
} from "ui";
import { useNavigationLayout } from "navigation";
import {
  DrawerLayoutMenuSection,
  DrawerLayoutMenuItem,
  DrawerLayoutMenuToggle,
} from "layout/drawer";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  paper: ({ openedWidth, paddingTop, openingTransition }) => ({
    position: "relative",
    width: openedWidth,
    paddingTop,
    paddingLeft: theme.spacing(3),
    height: "100%",
    background: "transparent",
    border: "none",
    overflow: "hidden",
    zIndex: "auto",
    transition: openingTransition,
  }),
  paperClosed: ({ closedWidth, closingTransition }) => ({
    width: closedWidth,
    transition: closingTransition,
  }),
  container: {
    height: "100%",
  },
  content: {
    padding: theme.spacing(1, 2, 2, 0),
  },
  toggle: {
    width: "100%",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const DrawerLayoutMenu = ({
  context = "menu",
  value,
  menu,
  menuPluginTop,
  menuPluginBottom,
  menuPluginBaseline,
}) => {
  // Layout
  const {
    headerProps: { height },
    menuProps: {
      closed,
      openedWidth,
      closedWidth,
      openingTransition,
      closingTransition,
      onClosed,
    } = {},
  } = useNavigationLayout();
  // Styles
  const classes = useStyles({
    paddingTop: height,
    openedWidth,
    closedWidth,
    openingTransition,
    closingTransition,
  });
  // Render
  return (
    <Section context={context} value={value}>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          classes={{
            paper: classnames(classes.paper, {
              [classes.paperClosed]: closed,
            }),
          }}
          open={!closed}
        >
          <Grid
            container
            direction="column"
            justify="space-between"
            wrap="nowrap"
            className={classes.container}
          >
            {isValidElement(menuPluginTop) && (
              <Grid item>{cloneElement(menuPluginTop, { closed })}</Grid>
            )}
            <Scrollable autoHide disableScrollX disableGutter>
              <Grid item>
                <div className={classes.content}>
                  {isValidElement(menu) &&
                    cloneElement(menu, {
                      sectionComponent: (
                        <DrawerLayoutMenuSection closed={closed} />
                      ),
                      itemComponent: <DrawerLayoutMenuItem closed={closed} />,
                    })}
                </div>
              </Grid>
            </Scrollable>
            <Spacer />
            {isValidElement(menuPluginBottom) && (
              <Grid item>
                <div className={classes.plugin}>
                  {cloneElement(menuPluginBottom, { closed })}
                </div>
              </Grid>
            )}
            <Grid item className={classes.toggle}>
              {menuPluginBaseline ?? (
                <DrawerLayoutMenuToggle closed={closed} onClosed={onClosed} />
              )}
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </Section>
  );
};

DrawerLayoutMenu.propTypes = {
  /**
   * Component context
   */
  context: PropTypes.string,
  /**
   * Component value
   */
  value: PropTypes.string,
  /**
   * The menu element to be displayed in the drawer.
   */
  menu: PropTypes.element,
  /**
   * The plugin element to be displayed at the top of the menu.
   */
  menuPluginTop: PropTypes.element,
  /**
   * The plugin element to be displayed at the bottom of the menu.
   */
  menuPluginBottom: PropTypes.element,
  /**
   * The plugin element to be displayed at the baseline of the menu.
   */
  menuPluginBaseline: PropTypes.element,
};

export default DrawerLayoutMenu;
