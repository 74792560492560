// React
import React, {
  forwardRef,
  useImperativeHandle,
  useCallback,
  useState,
} from "react";
import PropTypes from "prop-types";
// Framework
import { classnames } from "ui/classnames";
import { makeStyles, fade, Drawer } from "ui/components";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: fade(theme.palette.common.black, 0.1),
  },
  paper: {
    width: 360,
    maxWidth: "80%",
    height: `calc(100% - ${theme.spacing(3 + 3)}px)`,
    top: theme.spacing(3),
    right: theme.spacing(3),
    border: "none",
    borderRadius: theme.radius.large,
    boxShadow: theme.shadows[2],
  },
  paperFullWidth: {
    width: 600,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const DrawerLayoutFloatingTile = forwardRef(({ fullWidth, children }, ref) => {
  // Styles
  const classes = useStyles();
  // State
  const [open, setOpen] = useState(false);
  // Ref
  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
    },
  }));
  // Handlers
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  // Render
  return (
    <Drawer
      open={open}
      anchor="right"
      ModalProps={{
        BackdropProps: {
          classes: {
            root: classes.backdrop,
          },
        },
      }}
      classes={{
        paper: classnames(classes.paper, {
          [classes.paperFullWidth]: fullWidth,
        }),
      }}
      onClose={handleClose}
    >
      {children}
    </Drawer>
  );
});

DrawerLayoutFloatingTile.propTypes = {
  fullWidth: PropTypes.bool,
  children: PropTypes.any,
};

export default DrawerLayoutFloatingTile;
