// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty } from "@mefisto/utils";
// Framework
import {
  classnames,
  makeStyles,
  Section,
  Typography,
  List,
  Button,
  Cloak,
  Divider,
  Collapse,
} from "ui";
import { ChevronLeftRounded as ArrowIcon } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: theme.spacing(1, 0, 1, 2),
    color: theme.palette.text.secondary,
    background: "transparent",
    transition: theme.transitions.create("height"),
    "&:hover $endIcon": {
      visibility: "visible",
    },
    "&:hover": {
      color: theme.palette.action.active,
      background: "transparent",
    },
    "&:disabled": {
      color: theme.palette.text.secondary,
    },
  },
  section: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    width: "100%",
    height: theme.spacing(3),
  },
  divider: {
    width: theme.spacing(3),
  },
  title: {
    position: "relative",
    display: "inline-block",
    whiteSpace: "nowrap",
    textTransform: "uppercase",
    opacity: 1,
    fontWeight: 500,
    transition: theme.transitions.create("opacity"),
  },
  list: {
    padding: 0,
  },
  hidden: {
    opacity: 0,
  },
  endIcon: {
    marginLeft: theme.spacing(-0.25),
    transition: theme.transitions.create("transform"),
    transform: "rotate(270deg)",
    visibility: "hidden",
  },
  endIconClose: {
    transform: "rotate(180deg)",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const DrawerLayoutMenuSection = ({
  context = "section",
  value,
  title,
  disabled,
  closed,
  folded,
  foldable,
  onSelection,
  children,
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Section context={context} value={value}>
      <Cloak variant="remove" enabled={disabled}>
        <Button
          fullWidth
          disableRipple
          disabled={closed || !foldable || isEmpty(title)}
          classes={{
            root: classes.header,
            endIcon: classnames(classes.endIcon, {
              [classes.endIconClose]: folded,
            }),
          }}
          endIcon={title && !closed && <ArrowIcon color="inherit" />}
          onClick={onSelection}
        >
          {title && (
            <section className={classes.section}>
              {closed ? (
                <Divider className={classes.divider} />
              ) : (
                <Typography
                  variant="caption"
                  color="inherit"
                  className={classes.title}
                >
                  {title}
                </Typography>
              )}
            </section>
          )}
        </Button>
        <Collapse in={closed || !foldable || !folded}>
          <List className={classes.list}>{children}</List>
        </Collapse>
      </Cloak>
    </Section>
  );
};

DrawerLayoutMenuSection.propTypes = {
  /**
   * Component context
   */
  context: PropTypes.string,
  /**
   * Component value
   */
  value: PropTypes.string,
  /**
   * Section title
   */
  title: PropTypes.string,
  /**
   * Set to `true` if the menu is closed
   */
  closed: PropTypes.bool,
  /**
   * Set to `true` if the section is disabled
   */
  disabled: PropTypes.bool,
  /**
   Set to `true` if the section is foldable (can be folded)
   */
  foldable: PropTypes.bool,
  /**
   * Set to `true` if the section is folded
   */
  folded: PropTypes.bool,
  /**
   * Content of section (i.e. items)
   */
  children: PropTypes.node,
  /**
   * Called when user taps on the selection
   */
  onSelection: PropTypes.func,
};

export default DrawerLayoutMenuSection;
