// React
import React from "react";
// Framework
import { usePortal, forwardType, App, Permission } from "@mefisto/web";
// Icon
import { StorageIcon } from "icons";
// Loader
import { Loader } from "loader";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default forwardType(App, () => {
  // Framework
  const { config } = usePortal();
  const { appsStorageEnabled, appsStorageUrl } = config.values;
  // Render
  return (
    <App
      visible
      name="STORAGE"
      type="ORGANIZATION"
      enabled={appsStorageEnabled}
      title="Storage"
      color="#cf8880"
      description="Store all your documents at one place."
      priority={5}
      path={appsStorageUrl}
      icon={<StorageIcon />}
      loader={<Loader />}
      pricing={{
        month: 0,
        year: 0,
      }}
      logo={{
        icon: "app-icon-inverted-30",
        title: "Storage",
      }}
      theme={{
        palette: {
          primary: {
            dark: "#111b1f",
            main: "#273542",
            light: "#8d8d8d",
          },
          secondary: {
            dark: "#d16558",
            main: "#cf8880",
            light: "#cbb0ad",
          },
        },
      }}
      // prettier-ignore
      navigation={{
        basename: "storage",
        routes: {
          default: "/:organization/locations/:location/documents",
          notAuthorized: "/forbidden",
        },
        paths: {
          notAuthorized: "/forbidden",
          organization: "/:organization",
          location: "/:organization/locations/:location",
          dashboard: "/:organization/locations/:location/dashboard",
          documents: "/:organization/locations/:location/documents",
          document: "/:organization/locations/:location/documents/:document",
          categories: "/:organization/locations/:location/categories",
          category: "/:organization/locations/:location/categories/:category",
        },
      }}
      resource={{
        source: "navigation",
        redirectOnChange: true,
      }}
      permissions={[
        Permission("document.query", "Can read document data"),
        Permission("document.mutation", "Can edit document data"),
        Permission("category.query", "Can read category data"),
        Permission("category.mutation", "Can edit category data"),
      ]}
    />
  );
});
