// React
import React, { memo, useMemo } from "react";
// Helpers
import { map, range } from "@mefisto/utils";
// Framework
import { makeStyles, Skeleton, Box, Avatar } from "ui/components";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  skeleton: {
    opacity: 0.4,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TableRowSkeleton = ({ header, x, y }) => {
  // Styles
  const classes = useStyles();
  // Memo
  const width = useMemo(() => `${x > 1 ? 100 / (x + 1) : 100}%`, [x]);
  const mr = useMemo(() => `${x > 1 ? 100 / (x + 1) / x : 0}%`, [x]);
  // Render
  return (
    <Box
      position="absolute"
      overflow="hidden"
      width="100%"
      height="100%"
      bgcolor="common.white"
      zIndex="tooltip"
    >
      {header && (
        <>
          <Box display="flex" alignItems="center" px={3} mt={1} mb={1.5}>
            {map(range(x), (_, key) => (
              <Box key={key} width={width} mr={mr}>
                <Skeleton
                  width="100%"
                  animation={false}
                  className={classes.skeleton}
                />
              </Box>
            ))}
          </Box>
          <Box bgcolor="grey.100" width="100%" height={12} mt={1} />
        </>
      )}
      <Box px={3}>
        {map(range(y), (_, key) => (
          <Box key={key} display="flex" alignItems="center" mt={1} mb={2}>
            {map(range(x), (_, key) => (
              <Box
                key={key}
                display="flex"
                alignItems="center"
                width={width}
                mr={mr}
              >
                {key === 0 && (
                  <Box mr="10%">
                    <Skeleton
                      animation={false}
                      variant="circle"
                      className={classes.skeleton}
                    >
                      <Avatar />
                    </Skeleton>
                  </Box>
                )}
                <Skeleton
                  animation={false}
                  width="100%"
                  className={classes.skeleton}
                />
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default memo(TableRowSkeleton);
