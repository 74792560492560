// Helpers
import { isNil, filter, forEach } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";
// Components
import { CurrentUser } from "./service/CurrentUser";
import { Providers, ProviderId } from "./service/Provider";

export class Authentication extends StackDependency {
  #auth;
  #providers;
  #log;
  #currentUser;
  #subscribers = [];

  onInitialized() {
    const { firebase, log, cache } = this.context;
    this.#auth = firebase.auth();
    this.#providers = Providers(firebase);
    this.#log = log;
    this.#currentUser = new CurrentUser(
      firebase,
      log,
      cache,
      this.#handleChange
    );
  }

  /**
   * Subscribe to change.
   * Returns unsubscribe function you need to call in order to
   * stop the event observation in e.g. `useEffect` function.
   * @param callback {function} Callback called when state changes
   * @param callOnBind {boolean} If `true` the callback is called right after the callback is bound.
   * @return {function}
   */
  onChange(callback, callOnBind = false) {
    this.#subscribers.push(callback);
    if (callOnBind) {
      callback(this.#currentUser.toJSON());
    }
    return () => {
      this.#subscribers = filter(this.#subscribers, (s) => s !== callback);
    };
  }

  /**
   * Called when current user changes state or data
   */
  #handleChange = (user, error) => {
    // Handle error
    if (error) {
      this.#log.error(error);
      this.signOut();
      return;
    }
    // Handle disabled account
    if (user.data.disabled) {
      this.signOut();
      return;
    }
    // Notify
    forEach(this.#subscribers, (callback) => callback(user));
  };

  /**
   * Returns instance of current user
   * @return {CurrentUser}
   */
  get currentUser() {
    return this.#currentUser;
  }

  /**
   * Return current user's ID Token.
   */
  getIdToken(forceRefresh) {
    if (!this.#auth.currentUser) {
      return null;
    }
    return this.#auth.currentUser.getIdToken(forceRefresh);
  }

  /**
   * Returns provider based on providerId
   */
  getProvider(providerId) {
    const { provider } = this.#providers[providerId];
    if (!provider) {
      throw Error(`Unknown provider ${providerId}`);
    }
    return provider();
  }

  /**
   * Returns credential function for the given providerId
   */
  getCredential(providerId) {
    const { credential } = this.#providers[providerId];
    if (!credential) {
      throw Error(`Unknown provider ${providerId}`);
    }
    return credential;
  }

  /**
   * Sign in the user with email and password
   */
  signInWithEmailAndPassword(email, password) {
    return this.#auth.signInWithEmailAndPassword(email, password);
  }

  /**
   * Sign in the user with the given provider
   */
  signInWithProvider(providerId, popup) {
    const provider = this.getProvider(providerId);
    return popup
      ? this.#auth.signInWithPopup(provider)
      : this.#auth.signInWithRedirect(provider);
  }

  /**
   * Links the credential with the given provider and signs the user in
   */
  async signInWithLink(providerId, credential, email, password, popup) {
    const signIn = () => {
      if (providerId === ProviderId.Password) {
        return this.#auth.signInWithEmailAndPassword(email, password);
      } else {
        const provider = this.getProvider(providerId);
        return popup
          ? this.#auth.signInWithPopup(provider)
          : this.#auth.signInWithRedirect(provider);
      }
    };
    const { user } = await signIn();
    return user.linkAndRetrieveDataWithCredential(credential);
  }

  /**
   * Signs out the user
   */
  signOut() {
    this.#currentUser.unsync();
    return this.#auth.signOut();
  }

  /**
   * Returns list of sign in methods for the given email
   */
  fetchSignInMethodsForEmail(email) {
    return this.#auth.fetchSignInMethodsForEmail(email);
  }

  /**
   * Links provider with the user's current account
   */
  async linkProvider(providerId, popup) {
    const provider = this.getProvider(providerId);
    const { user } = popup
      ? await this.#auth.currentUser.linkWithPopup(provider)
      : await this.#auth.currentUser.linkWithRedirect(provider);
    return this.#currentUser.updateAuthData(user);
  }

  /**
   * Unlinks provider from the user's current account
   */
  async unlinkProvider(providerId) {
    const user = await this.#auth.currentUser.unlink(providerId);
    return this.#currentUser.updateAuthData(user);
  }

  /**
   * Call to change user password
   */
  async changePassword(currentPassword, newPassword) {
    const passwordProvider = this.#currentUser.passwordProvider;
    // User doesn't have a password yet
    if (!passwordProvider) {
      await this.#auth.currentUser.updatePassword(newPassword);
      this.#currentUser.updateAuthData(this.#auth.currentUser);
      return;
    }
    // Reauthenticate with old password
    const credential = this.getCredential(ProviderId.Password);
    await this.#auth.currentUser.reauthenticateWithCredential(
      credential(passwordProvider.email, currentPassword)
    );
    // Set new password
    await this.#auth.currentUser.updatePassword(newPassword);
    return this.#currentUser.updateAuthData(this.#auth.currentUser);
  }

  /**
   * Verifies password reset code
   */
  verifyPasswordResetCode(code) {
    return this.#auth.verifyPasswordResetCode(code);
  }

  /**
   * Confirms new password
   */
  confirmPasswordReset(code, password) {
    return this.#auth.confirmPasswordReset(code, password);
  }

  /**
   * Applies verification action code
   */
  async applyActionCode(code) {
    await this.#auth.applyActionCode(code);
    // We need to reload the user because the `emailVerified` prop
    // is not automatically updated
    if (!isNil(this.#auth.currentUser)) {
      await this.#auth.currentUser.reload();
    }
    return this.#currentUser.updateAuthData(this.#auth.currentUser);
  }
}
