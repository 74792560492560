// Framework
import { withStyles, AccordionActions } from "@material-ui/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default withStyles((theme) => ({
  root: {
    background: theme.palette.grey[200],
    borderTop: "none",
    borderBottom: "none",
    padding: theme.spacing(3),
    minHeight: 56,
  },
}))(AccordionActions);
