// React
import React, {
  Children,
  isValidElement,
  cloneElement,
  useState,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { isNil, find, get } from "@mefisto/utils";
// Framework
import { Menu } from "ui";
import { ChipInput } from "form/input";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  label,
  fullWidth,
  children,
  field: { name },
  form: { values, touched, errors, setFieldValue },
}) => {
  // Memo
  const errorText = useMemo(() => {
    const key = find(name, (name) => errors[name]);
    return get(errors, key);
  }, [errors, name]);
  const hasError = useMemo(() => {
    const key = find(name, (name) => touched[name]);
    return get(touched, key) && Boolean(errorText);
  }, [touched, name, errorText]);
  const key = useMemo(() => {
    return find(name, (name) => values[name]);
  }, [name, values]);
  const value = useMemo(() => {
    return !isNil(key) ? [values[key]] : [];
  }, [key, values]);
  const icon = useMemo(() => {
    const choice = find(Children.toArray(children), (child) => {
      return isValidElement(child) && child.props.value === key;
    });
    return choice?.props?.icon;
  }, [children, key]);
  // State
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // Handlers
  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);
  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);
  const handleClear = useCallback(() => {
    setFieldValue(key, "");
  }, [setFieldValue, key]);
  const handleSelection = useCallback(
    ({ value, data }) => {
      Children.forEach(children, (child) => {
        if (isValidElement(child)) {
          // Clear all the other values
          const fieldValue = child.props.value === value ? data : "";
          setFieldValue(child.props.value, fieldValue);
        }
      });
      setOpen(false);
    },
    [children, setFieldValue]
  );
  const handleOpen = useCallback((event) => {
    event.stopPropagation();
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setFocused(true);
  }, []);
  // Render
  return (
    <>
      <ChipInput
        value={value}
        icon={icon}
        label={label}
        focused={focused}
        fullWidth={fullWidth}
        error={hasError ? errorText : null}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleOpen}
        onDelete={handleClear}
      />
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {Children.map(
          children,
          (child) =>
            isValidElement(child) &&
            cloneElement(child, {
              onSelection: handleSelection,
            })
        )}
      </Menu>
    </>
  );
};

const FormChoiceField = (props) => (
  <FormField variant="outlined" fullWidth component={Component} {...props} />
);

FormChoiceField.propTypes = {
  children: PropTypes.node,
};

export default FormChoiceField;
