// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { Section } from "ui/section";
import { classnames } from "ui/classnames";
import { makeStyles, fade, Dialog as CoreDialog } from "@material-ui/core";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  backdropBlur: {
    backgroundColor: fade(theme.palette.common.black, 0.15),
    color: theme.palette.text.primary,
    backdropFilter: "blur(3px)",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({ disableBackdropBlur, fullScreen, ...props }) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <CoreDialog
      {...props}
      fullScreen={fullScreen}
      BackdropProps={{
        className: classnames({
          [classes.backdropBlur]: !disableBackdropBlur && !fullScreen,
        }),
        ...props.BackdropProps,
      }}
    />
  );
};

const Dialog = ({ context = "dialog", value, children, ...props }) => (
  <Section context={context} value={value}>
    <Component {...props}>{children}</Component>
  </Section>
);

Dialog.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string,
  /**
   * The id(s) of the element(s) that describe the dialog.
   */
  "aria-describedby": PropTypes.string,
  /**
   * The id(s) of the element(s) that label the dialog.
   */
  "aria-labelledby": PropTypes.string,
  /**
   * Dialog children, usually the included sub-components.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,
  /**
   * If `true`, hitting escape will not fire the `onClose` callback.
   */
  disableEscapeKeyDown: PropTypes.bool,
  /**
   * If `true`, the backdrop blur is not applied.
   */
  disableBackdropBlur: PropTypes.bool,
  /**
   * If `true`, the dialog will be full-screen
   */
  fullScreen: PropTypes.bool,
  /**
   * If `true`, the dialog stretches to `maxWidth`.
   *
   * Notice that the dialog width grow is limited by the default margin.
   */
  fullWidth: PropTypes.bool,
  /**
   * Determine the max-width of the dialog.
   * The dialog width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs", false]),
  /**
   * Callback fired when the component requests to be closed.
   *
   * @param {object} event The event source of the callback.
   * @param {string} reason Can be: `"escapeKeyDown"`, `"backdropClick"`.
   */
  onClose: PropTypes.func,
  /**
   * If `true`, the Dialog is open.
   */
  open: PropTypes.bool.isRequired,
  /**
   * The component used to render the body of the dialog.
   */
  PaperComponent: PropTypes.elementType,
  /**
   * Props applied to the [`Paper`](/api/paper/) element.
   */
  PaperProps: PropTypes.object,
  /**
   * Determine the container for scrolling the dialog.
   */
  scroll: PropTypes.oneOf(["body", "paper"]),
  /**
   * The component used for the transition.
   * [Follow this guide](/components/transitions/#transitioncomponent-prop) to learn more about the requirements for this component.
   */
  TransitionComponent: PropTypes.elementType,
  /**
   * The duration for the transition, in milliseconds.
   * You may specify a single timeout for all transitions, or individually with an object.
   */
  transitionDuration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number,
    }),
  ]),
  /**
   * Props applied to the [`Transition`](http://reactcommunity.org/react-transition-group/transition#Transition-props) element.
   */
  TransitionProps: PropTypes.object,
};

export default Dialog;
