// React
import React, {
  forwardRef,
  isValidElement,
  cloneElement,
  useRef,
  useCallback,
} from "react";
import PropTypes from "prop-types";
// Framework
import { MenuItem, ListItemIcon, ListItemText } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const FormChoiceFieldOption = forwardRef(
  ({ value, title, icon, picker, onSelection }, ref) => {
    // Ref
    const pickerRef = useRef(null);
    // Callbacks
    const handleClick = useCallback(() => {
      pickerRef.current.open();
    }, []);
    const handleSelection = useCallback(
      (data) => {
        onSelection({ value, title, icon, data });
      },
      [value, title, icon, onSelection]
    );
    // Render
    return (
      <>
        <MenuItem dense ref={ref} onClick={handleClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </MenuItem>
        {isValidElement(picker) &&
          cloneElement(picker, {
            ref: pickerRef,
            onSelection: handleSelection,
          })}
      </>
    );
  }
);

FormChoiceFieldOption.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  picker: PropTypes.node,
};

export default FormChoiceFieldOption;
