// React
import React from "react";
// Framework
import { usePortal, forwardType, App, Permission } from "@mefisto/web";
// Icon
import { MaintenanceIcon } from "icons";
// Loader
import { Loader } from "loader";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default forwardType(App, () => {
  // Framework
  const { config } = usePortal();
  const {
    appsMaintenanceEnabled,
    appsMaintenanceUrl,
    appsMaintenanceLearnUrl,
  } = config.values;
  // Render
  return (
    <App
      visible
      name="MAINTENANCE"
      type="ORGANIZATION"
      enabled={appsMaintenanceEnabled}
      title="Maintenance"
      description="A better way to keep your restaurant R&M organized."
      color="#6455cb"
      priority={3}
      hasDocuments={true}
      pricing={{
        month: 29,
        year: 290,
      }}
      path={appsMaintenanceUrl}
      learnPath={appsMaintenanceLearnUrl}
      icon={<MaintenanceIcon />}
      loader={<Loader />}
      logo={{
        icon: "app-icon-inverted-30",
        title: "Maintenance",
      }}
      theme={{
        palette: {
          primary: {
            dark: "#111b1f",
            main: "#273542",
            light: "#8d8d8d",
          },
          secondary: {
            dark: "#3626a0",
            main: "#6455cb",
            light: "#aba3e2",
          },
        },
      }}
      // prettier-ignore
      navigation={{
        basename: "maintenance",
        routes: {
          default: "/:organization/locations/:location/dashboard",
          notAuthorized: "/forbidden",
        },
        paths: {
          notAuthorized: "/forbidden",
          organization: "/:organization",
          location: "/:organization/locations/:location",
          dashboard: "/:organization/locations/:location/dashboard",
          reports: "/:organization/locations/:location/reports",
          weeklyReport: "/:organization/locations/:location/reports/weekly/:weeklyReport",
          monthlyReport: "/:organization/locations/:location/reports/monthly/:monthlyReport",
          services: "/:organization/locations/:location/services",
          upcomingService: "/:organization/locations/:location/services/upcoming/:upcomingService/:date",
          pendingService: "/:organization/locations/:location/services/pending/:pendingService",
          completedService: "/:organization/locations/:location/services/completed/:completedService",
          rejectedService: "/:organization/locations/:location/services/rejected/:rejectedService",
          equipments: "/:organization/locations/:location/equipment",
          equipment: "/:organization/locations/:location/equipment/:equipment",
          groups: "/:organization/locations/:location/groups",
          group: "/:organization/locations/:location/groups/:group",
          costs: "/:organization/locations/:location/costs",
          events: "/:organization/locations/:location/events",
          vendors: "/:organization/locations/:location/vendors",
          vendor: "/:organization/locations/:location/vendors/:vendor",
          areas: "/:organization/locations/:location/areas",
          area: "/:organization/locations/:location/areas/:area",
          categories: "/:organization/locations/:location/categories",
          category: "/:organization/locations/:location/categories/:category",
          documents: "/:organization/locations/:location/documents",
          transfer: "/:organization/locations/:location/transfer",
          codes: "/:organization/locations/:location/codes",
          flows: "/:organization/locations/:location/flows",
          flow: "/:organization/locations/:location/flow/:flow",
        },
      }}
      resource={{
        source: "navigation",
        redirectOnChange: true,
      }}
      permissions={[
        Permission("area.query", "Can read area data"),
        Permission("area.mutation", "Can edit area data"),
        Permission("category.query", "Can read category data"),
        Permission("category.mutation", "Can edit category data"),
        Permission("code.query", "Can read code data"),
        Permission("code.mutation", "Can edit code data"),
        Permission("completedService.query", "Can read completed service data"),
        Permission(
          "completedService.mutation",
          "Can completed service area data"
        ),
        Permission("costs.query", "Can read costs analytics"),
        Permission("dashboard.query", "Can access dashboard"),
        Permission("equipment.query", "Can read equipment data"),
        Permission("equipment.mutation", "Can edit equipment data"),
        Permission("flow.query", "Can read service flow data"),
        Permission("flow.mutation", "Can edit service flow data"),
        Permission("group.query", "Can read group data"),
        Permission("group.mutation", "Can edit group data"),
        Permission("import.mutation", "Can import data"),
        Permission("monthlyReport.query", "Can read monthly report data"),
        Permission("monthlyReport.mutation", "Can edit monthly report data"),
        Permission("parse.query", "Can read parsed imported data"),
        Permission("pendingService.query", "Can read pendingService data"),
        Permission("pendingService.mutation", "Can pendingService area data"),
        Permission("rejectedService.query", "Can read rejected service data"),
        Permission(
          "rejectedService.mutation",
          "Can rejected service area data"
        ),
        Permission("service.query", "Can read service data"),
        Permission("upcomingService.query", "Can read upcomingService data"),
        Permission("upcomingService.mutation", "Can edit upcomingService data"),
        Permission("vendor.query", "Can read vendor data"),
        Permission("vendor.mutation", "Can edit vendor data"),
        Permission("weeklyReport.query", "Can read weekly report data"),
        Permission("weeklyReport.mutation", "Can edit weekly report data"),
      ]}
    />
  );
});
