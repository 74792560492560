// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { classnames, makeStyles } from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    width: "100%",
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const DrawerLayoutHeader = ({ children }) => {
  // Styles
  const classes = useStyles();
  // Render
  return <div className={classnames(classes.root)}>{children}</div>;
};

DrawerLayoutHeader.propTypes = {
  /**
   * Header content
   */
  children: PropTypes.node,
};

export default DrawerLayoutHeader;
