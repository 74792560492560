// React
import React, { useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { first } from "@mefisto/utils";
// Dependencies
import { Activity, ActivityProvider } from "activity/core";
import { Agent, AgentProvider } from "agent/core";
import { Analytics, AnalyticsProvider } from "analytics/core";
import { Apps, AppsProvider } from "apps/core";
import { Authentication, AuthenticationProvider } from "authentication/core";
import { Authorization, AuthorizationProvider } from "authorization/core";
import { Cache, CacheProvider } from "cache/core";
import { Config, ConfigProvider } from "config/core";
import { Externals, ExternalsProvider } from "externals/core";
import { Firebase, FirebaseProvider } from "firebase/core";
import { Foggle, FoggleProvider } from "foggle/core";
import { Format, FormatProvider } from "format/core";
import { HelmetProvider } from "helmet/core";
import { Interceptor, InterceptorProvider } from "interceptor/core";
import { Localization, LocalizationProvider } from "localization/core";
import { Log, LogProvider } from "log/core";
import { Messaging, MessagingProvider } from "messaging/core";
import { Navigation, NavigationProvider } from "navigation/core";
import { Performance, PerformanceProvider } from "performance/core";
import { Permission, PermissionProvider } from "permission/core";
import { PWA, PWAProvider } from "pwa/core";
import { Resource, ResourceProvider } from "resource/core";
import { ServiceWorker, ServiceWorkerProvider } from "sw/core";
import { Snackbar, SnackbarProvider } from "snackbar/core";
import { Storage, StorageProvider } from "storage/core";
import { Theme, ThemeProvider } from "theme/core";
import { Time, TimeProvider } from "time/core";
import { UI, UIProvider } from "ui/core";
import { UploadQueue, UploadQueueProvider } from "uploadQueue/core";
// Components
import { PortalProvider } from "stack/core";
import { PortalLoader } from "stack/components";
import { useChildrenTypes } from "type";
import { SnackbarBridge } from "snackbar";
import { FormLocalization } from "form";
import { PWAVersionSnackbar } from "pwa";
import { UploadBlocker, UploadError, UploadSnackbar } from "uploadQueue";

// List of Portal dependencies
const dependencies = {
  Activity,
  Agent,
  Analytics,
  Apps,
  Authentication,
  Authorization,
  Cache,
  Config,
  Externals,
  Firebase,
  Foggle,
  Format,
  Interceptor,
  Localization,
  Log,
  Messaging,
  Navigation,
  Performance,
  Permission,
  Resource,
  PWA,
  ServiceWorker,
  Snackbar,
  Storage,
  Theme,
  Time,
  UI,
  UploadQueue,
};

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Portal = ({ children }) => {
  // Framework
  const types = useChildrenTypes(children, [
    "App",
    "Cache",
    "Config",
    "Externals",
    "Firebase",
    "Foggle",
    "Format",
    "Helmet",
    "Interceptors",
    "Localization",
    "Navigation",
    "Themes",
    "PortalLayoutPlugin",
  ]);
  // Memo
  // noinspection JSUnresolvedVariable
  const options = useMemo(
    () => ({
      apps: types.App,
      cache: first(types.Cache),
      config: first(types.Config),
      externals: first(types.Externals),
      firebase: first(types.Firebase),
      foggle: first(types.Foggle),
      format: first(types.Format),
      helmet: first(types.Helmet),
      interceptors: first(types.Interceptors),
      localization: first(types.Localization),
      navigation: first(types.Navigation),
      themes: first(types.Themes),
      layoutPlugins: types.PortalLayoutPlugin,
    }),
    [types]
  );
  // Render
  return (
    <PortalProvider
      dependencies={dependencies}
      providers={[
        <ActivityProvider />,
        <AgentProvider />,
        <ConfigProvider options={options.config} />,
        <FoggleProvider options={options.foggle} />,
        <LogProvider />,
        <ServiceWorkerProvider />,
        <PWAProvider />,
        <CacheProvider options={options.cache} />,
        <ExternalsProvider options={options.externals} />,
        <HelmetProvider options={options.helmet} />,
        <FirebaseProvider options={options.firebase} />,
        <MessagingProvider />,
        <PerformanceProvider />,
        <TimeProvider />,
        <AuthenticationProvider />,
        <UIProvider />,
        <StorageProvider />,
        <FormatProvider options={options.format} />,
        <NavigationProvider options={options.navigation} />,
        <AppsProvider options={options.apps} />,
        <LocalizationProvider source="portal" options={options.localization} />,
        <AuthorizationProvider />,
        <PermissionProvider />,
        <ResourceProvider />,
        <AnalyticsProvider />,
        <ThemeProvider options={options.themes} />,
        <SnackbarProvider />,
        <UploadQueueProvider />,
        <InterceptorProvider source="portal" options={options.interceptors} />,
      ]}
    >
      <PortalLoader layoutPlugins={options.layoutPlugins}>
        <SnackbarBridge />
        <UploadBlocker />
        <UploadSnackbar />
        <UploadError />
        <FormLocalization />
        <PWAVersionSnackbar />
      </PortalLoader>
    </PortalProvider>
  );
};

Portal.propTypes = {
  children: PropTypes.node,
};

export default Portal;
