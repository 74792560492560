// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import {
  classnames,
  makeStyles,
  useMediaQuery,
  Section,
  Box,
  Grid,
  Spacer,
  Typography,
} from "ui";
import { useTheme } from "theme";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  inset: {
    paddingLeft: theme.spacing(5),
  },
  disableGutter: {
    paddingBottom: 0,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SceneTitle = ({
  context = "title",
  value,
  size = "small",
  title,
  description,
  image,
  inset,
  appendix,
  disableGutter,
}) => {
  // Styles
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  // Render
  return (
    <Section context={context} value={value}>
      <div
        className={classnames(
          classes.root,
          disableGutter && classes.disableGutter
        )}
      >
        <Grid
          container
          spacing={3}
          alignItems="flex-start"
          wrap={xs ? "wrap" : "nowrap"}
        >
          <Grid item>
            <Grid container wrap="nowrap" spacing={2}>
              {image && <Grid item>{image}</Grid>}
              <Grid item>
                <Grid container direction="column">
                  {title && (
                    <Grid item>
                      <Typography variant="h6" component="div">
                        <Box
                          fontSize={size === "medium" ? "1.5rem" : "inherit"}
                        >
                          {title}
                        </Box>
                      </Typography>
                    </Grid>
                  )}
                  {description && (
                    <Grid item className={classnames(inset && classes.inset)}>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        component="div"
                      >
                        {description}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {appendix && (
            <>
              {!xs && <Spacer />}
              <Grid flex={xs} item>
                {appendix}
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </Section>
  );
};

SceneTitle.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  image: PropTypes.node,
  inset: PropTypes.bool,
  appendix: PropTypes.node,
  disableGutter: PropTypes.bool,
};

export default SceneTitle;
