// React
import React, {
  Children,
  isValidElement,
  cloneElement,
  useEffect,
  useState,
  useCallback,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { merge } from "@mefisto/utils";
// Framework
import { makeStyles, Fade } from "ui";
import { useTheme } from "theme";
import { useMounted } from "hooks";
import { usePortal } from "stack/core";
import { useBreakpoint } from "ui/hooks";
import { NavigationLayout } from "navigation";
import { SceneLoader } from "layout/components";
// Components
import {
  DrawerLayoutHeader,
  DrawerLayoutScene,
  DrawerLayoutMenu,
} from "layout/drawer";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    height: "100%",
  },
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: "100%",
  },
  content: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const DrawerLayout = ({
  header,
  menu,
  menuPluginTop,
  menuPluginBottom,
  menuPluginBaseline,
  pluginLeft,
  pluginRight,
  bottomBar,
  loader,
  layoutProps,
  children,
}) => {
  // Framework
  const { ui } = usePortal();
  const { callWhenMounted } = useMounted();
  // Styles
  const classes = useStyles();
  const theme = useTheme();
  // Layout
  const { breakpoint: leadingSectionWidth } = useBreakpoint({
    sm: "auto",
    md: 280,
  });
  const { breakpoint: display } = useBreakpoint({
    sm: "default",
    md: "rounded",
  });
  // State
  const [closed, setClosed] = useState(ui.get("drawerClosed"));
  // Handlers
  const handleClosed = useCallback(() => {
    ui.set("drawerClosed", !closed);
  }, [ui, closed]);
  // Effects
  useEffect(() => {
    return ui.onChange(
      callWhenMounted((ui) => {
        setClosed(ui.get("drawerClosed"));
      })
    );
  }, [callWhenMounted, ui]);
  // Render
  return (
    <NavigationLayout
      layoutProps={merge(
        {
          headerProps: {
            position: "relative",
            display,
            horizontalFill: true,
            compact: closed,
            height: 52,
            leadingSectionWidth: closed ? 96 : leadingSectionWidth,
          },
          headerLogoProps: {
            hideTitleIcon: closed,
          },
          sceneProps: {
            verticalFill: true,
            horizontalFill: "md",
            contentGutterTop: 52,
          },
          menuProps: {
            closed,
            onClosed: handleClosed,
            openedWidth: 280,
            closedWidth: 96,
            baselineHeight: 60,
            openingTransition: theme.transitions.create(["width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            closingTransition: theme.transitions.create(["width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          },
        },
        layoutProps
      )}
    >
      <Fade in>
        <div className={classes.root}>
          {pluginLeft && <div>{pluginLeft}</div>}
          <div className={classes.container}>
            <div className={classes.content}>
              {isValidElement(header) && (
                <DrawerLayoutHeader>
                  {cloneElement(header, {
                    menu,
                    menuPluginTop,
                    menuPluginBottom,
                  })}
                </DrawerLayoutHeader>
              )}
              <DrawerLayoutMenu
                menu={menu}
                menuPluginTop={menuPluginTop}
                menuPluginBottom={menuPluginBottom}
                menuPluginBaseline={menuPluginBaseline}
                closed={closed}
                onClosed={handleClosed}
              />
              <DrawerLayoutScene closed={closed}>
                {Children.map(
                  children,
                  (scene) =>
                    isValidElement(scene) &&
                    cloneElement(scene, {
                      loader: loader ?? SceneLoader,
                    })
                )}
              </DrawerLayoutScene>
            </div>
            {bottomBar}
          </div>
          {pluginRight && <div>{pluginRight}</div>}
        </div>
      </Fade>
    </NavigationLayout>
  );
};

DrawerLayout.propTypes = {
  /**
   * The header element to be displayed at the top of the drawer.
   */
  header: PropTypes.element,
  /**
   * The menu element to be displayed in the drawer.
   */
  menu: PropTypes.element,
  /**
   * The plugin element to be displayed at the top of the menu.
   */
  menuPluginTop: PropTypes.element,
  /**
   * The plugin element to be displayed at the bottom of the menu.
   */
  menuPluginBottom: PropTypes.element,
  /**
   * The plugin element to be displayed at the baseline of the menu.
   */
  menuPluginBaseline: PropTypes.element,
  /**
   * The plugin element to be displayed on the left side of the layout.
   */
  pluginLeft: PropTypes.element,
  /**
   * The plugin element to be displayed on the right side of the layout.
   */
  pluginRight: PropTypes.element,
  /**
   * The bottom bar element to be displayed at the bottom of the layout.
   */
  bottomBar: PropTypes.element,
  /**
   * The loader element passed to scene.
   */
  loader: PropTypes.any,
  /**
   * The scenes to be displayed in the layout.
   */
  children: PropTypes.node,
};

export default DrawerLayout;
