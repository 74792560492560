// React
import React, { useCallback } from "react";
// Framework
import { usePortal } from "stack";
import { Hidden, IconButton } from "ui";
import { ArrowBackIosRounded as BackIcon } from "icon/material";
// Components
import HeaderItem from "../HeaderItem";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderBackButton = () => {
  // Framework
  const { navigation } = usePortal();
  // Handlers
  const handleClick = useCallback(() => {
    navigation.goBack();
  }, [navigation]);
  // Render
  return (
    <Hidden smDown>
      <HeaderItem value="back">
        <IconButton edge="start" color="inherit" onClick={handleClick}>
          <BackIcon />
        </IconButton>
      </HeaderItem>
    </Hidden>
  );
};

export default HeaderBackButton;
