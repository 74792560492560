// React
import React, { useEffect, useState } from "react";
// Framework
import { usePortal } from "stack";
import { makeStyles, Activity } from "ui";
import { useMounted } from "hooks";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: theme.zIndex.modal,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ActivityComponent = () => {
  // Framework
  const { activity } = usePortal();
  const { callWhenMounted } = useMounted();
  // Styles
  const classes = useStyles();
  // State
  const [active, setActive] = useState(false);
  // Effects
  useEffect(() => {
    return activity.onChange(
      callWhenMounted(() => {
        setActive(activity.running());
      }),
      true
    );
  }, [callWhenMounted, activity]);
  // Render
  return (
    <>
      {active && (
        <div className={classes.root}>
          <Activity />
        </div>
      )}
    </>
  );
};

export default ActivityComponent;
