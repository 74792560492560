// React
import React, { Fragment, useCallback, useRef, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { map, filter, size, isEmpty, flatMap } from "@mefisto/utils";
// Framework
import {
  makeStyles,
  forwardType,
  usePortal,
  useApps,
  useUser,
  AppType,
  AuthState,
  classnames,
  fade,
  Grid,
  FlatIcon,
  Scrollable,
  PortalLayoutPlugin,
  Avatar,
  Button,
} from "@mefisto/web";
// Components
import { AccountPopover } from "components";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(9),
    height: "100%",
    overflow: "hidden auto",
    background: fade(theme.palette.common.black, 0.05),
  },
  content: {
    padding: theme.spacing(1.5, 0),
  },
  top: {
    flex: 1,
  },
  divider: {
    width: theme.spacing(4),
    height: theme.spacing(0.25),
    borderRadius: 1,
    background: theme.palette.grey[300],
    margin: theme.spacing(0.5, 0),
  },
  container: {
    position: "relative",
  },
  bottom: {
    height: 60,
  },
  account: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  gutter: {
    paddingTop: theme.spacing(4),
  },
  current: ({ position }) => ({
    position: "absolute",
    display: "block",
    ...(position === "leading" && {
      left: theme.spacing(-1.5),
      borderRadius: "0 4px 4px 0",
    }),
    ...(position === "trailing" && {
      right: theme.spacing(-1.5),
      borderRadius: "4px 0px 0 4px",
    }),
    width: theme.spacing(0.5),
    height: theme.spacing(6),
    background: theme.palette.action.active,
  }),
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const AppPortalLayoutPlugin = forwardType(
  PortalLayoutPlugin,
  ({ position, gutter }) => {
    // Framework
    const {
      user: {
        state,
        data: { name, profileImage },
      },
    } = useUser();
    const { navigation } = usePortal();
    const { organization, user, current, all } = useApps();
    // Styles
    const classes = useStyles({ position });
    // Ref
    const popoverRef = useRef(null);
    // Memo
    const apps = useMemo(() => {
      const apps = filter(
        [
          filter(all, { visible: true, type: AppType.NATIVE }),
          filter(user, { visible: true }),
          filter(organization, { visible: true }),
        ],
        (apps) => !isEmpty(apps)
      );
      return flatMap(apps, (el, i) => {
        return i === size(apps) - 1 ? el : [...el, "divider"];
      });
    }, [user, organization, all]);
    // Callbacks
    const isCurrent = useCallback(
      (app) => {
        return app.id === current?.id;
      },
      [current]
    );
    // Handlers
    const handleSelection = useCallback(
      (app) => () => {
        const id = current?.id;
        if (app.id !== id) {
          navigation.goTo(app.navigation.basename);
        }
      },
      [current, navigation]
    );
    const handleAccount = useCallback((event) => {
      popoverRef.current.open({
        anchorEl: event.currentTarget,
      });
    }, []);
    // Render
    return (
      <>
        {state === AuthState.authenticated && (
          <PortalLayoutPlugin position={position}>
            <AccountPopover
              ref={popoverRef}
              popoverProps={{
                offset: { top: -2 },
                anchorOrigin: { vertical: "top", horizontal: "left" },
                transformOrigin: { vertical: "bottom", horizontal: "left" },
              }}
            />
            <div
              className={classnames(classes.root, {
                [classes.gutter]: gutter,
              })}
            >
              <div className={classes.top}>
                <Scrollable autoHide disableScrollX disableGutter>
                  <div className={classes.content}>
                    <Grid
                      container
                      direction="column"
                      wrap="nowrap"
                      alignItems="center"
                      spacing={1}
                    >
                      {map(apps, (app, key) => (
                        <Fragment key={key}>
                          {app === "divider" ? (
                            <Grid item>
                              <div className={classes.divider} />
                            </Grid>
                          ) : (
                            <Grid item>
                              <div className={classes.container}>
                                {isCurrent(app) && (
                                  <div className={classes.current} />
                                )}
                                <FlatIcon
                                  size="medium"
                                  icon={app.icon}
                                  color={app.color}
                                  onClick={handleSelection(app)}
                                />
                              </div>
                            </Grid>
                          )}
                        </Fragment>
                      ))}
                    </Grid>
                  </div>
                </Scrollable>
              </div>
              <div className={classes.bottom}>
                <Button className={classes.account} onClick={handleAccount}>
                  <Avatar title={name} image={profileImage} size={40} />
                </Button>
              </div>
            </div>
          </PortalLayoutPlugin>
        )}
      </>
    );
  }
);

AppPortalLayoutPlugin.propTypes = {
  position: PropTypes.oneOf(["leading", "trailing"]).isRequired,
  gutter: PropTypes.bool,
};

export default AppPortalLayoutPlugin;
