// React
import React, { useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { join, map, range } from "@mefisto/utils";
// Framework
import { usePortal } from "stack/core";
import { useNavigationLayout } from "navigation";
import { makeStyles, classnames, Grid, Hidden, Typography, Cloak } from "ui";
// Components
import HeaderItem from "../HeaderItem";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: "none",
  },
  selectable: {
    cursor: "pointer",
  },
  image: ({ size }) => ({
    width: "auto",
    height: size,
  }),
  icon: {
    display: "flex",
    width: theme.spacing(6),
  },
  title: {
    fontSize: "1.1rem",
  },
  titleContainer: {
    display: "flex",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderLogo = ({ ...headerLogoProps }) => {
  const {
    headerLogoProps: {
      appIcon = "app-icon-30",
      titleIcon = "app-title-30",
      iconFolder = "icon",
      disableAppIcon,
      disableTitleIcon,
      disableSelection,
      hideAppIcon,
      hideTitleIcon,
      title,
      size = 30,
    },
  } = useNavigationLayout({ headerLogoProps });
  // Styles
  const classes = useStyles({ size });
  // Framework
  const { navigation, config } = usePortal();
  const { publicUrl } = config.values;
  // Callback
  const getSrcSet = useCallback(
    (src) => {
      return join(
        map(
          range(1, 4),
          (index) => `${publicUrl}/${iconFolder}/${src}@${index}x.png ${index}x`
        ),
        ","
      );
    },
    [publicUrl, iconFolder]
  );
  // Handlers
  const handleClick = useCallback(() => {
    if (!disableSelection) {
      navigation.goTo(navigation.routes.home);
      if (window.scrollTo) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [navigation, disableSelection]);
  // Render
  return (
    <HeaderItem value="logo">
      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        className={classnames(classes.root, {
          [classes.selectable]: !disableSelection,
        })}
        onClick={handleClick}
      >
        {!disableAppIcon && (
          <Cloak variant="remove" enabled={hideAppIcon}>
            <Grid item className={classes.icon}>
              <img
                alt=""
                src={`${publicUrl}/${iconFolder}/${appIcon}@1x.png`}
                srcSet={getSrcSet(appIcon)}
                className={classes.image}
              />
            </Grid>
          </Cloak>
        )}
        {!disableTitleIcon && (
          <Hidden smDown>
            <Cloak variant="remove" enabled={hideTitleIcon}>
              <Grid item className={classes.titleContainer}>
                {title && (
                  <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.title}
                  >
                    {title}
                  </Typography>
                )}
                {titleIcon && !title && (
                  <img
                    alt=""
                    src={`${publicUrl}/${iconFolder}/${titleIcon}@1x.png`}
                    srcSet={getSrcSet(titleIcon)}
                    className={classes.image}
                  />
                )}
              </Grid>
            </Cloak>
          </Hidden>
        )}
      </Grid>
    </HeaderItem>
  );
};

HeaderLogo.propTypes = {
  appIcon: PropTypes.string,
  title: PropTypes.string,
  titleIcon: PropTypes.string,
  disableSelection: PropTypes.bool,
  disableAppIcon: PropTypes.bool,
  disableTitleIcon: PropTypes.bool,
  hideAppIcon: PropTypes.bool,
  hideTitleIcon: PropTypes.bool,
};

export default HeaderLogo;
