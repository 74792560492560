// Helpers
import {
  filter,
  forEach,
  find,
  get,
  map,
  has,
  reduce,
  first,
  split,
  toUpper,
  merge,
} from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";

/**
 * @typedef {Object} Language
 * @property {string} title
 * @property {string} locale
 * @property {any} icon
 */

export class Localization extends StackDependency {
  #cache;
  #log;
  #fallback;
  #messages;
  #languages;
  #availableLanguages;
  #localizations = {};
  #subscribers = [];

  onInitialized() {
    const { cache, log } = this.context;
    const { fallback, languages } = this.options;
    this.#log = log;
    this.#cache = cache;
    this.#fallback = fallback;
    this.#languages = reduce(
      languages,
      (result, { props }) => {
        result[props.locale] = {
          ...props,
          code: this.#getCode(props),
        };
        return result;
      },
      {}
    );
    this.#availableLanguages = map(this.#languages, ({ code }) => code);
  }

  /**
   * Subscribe to authentication changes.
   * Returns unsubscribe function you need to call in order to
   * stop the event observation in e.g. `useEffect` function.
   * @param callback {function} Callback called when state changes
   * @param callOnBind {boolean} If `true` the callback is called right after the callback is bound.
   * @return {function}
   */
  onChange(callback, callOnBind = false) {
    this.#subscribers.push(callback);
    if (callOnBind) {
      callback(this);
    }
    return () => {
      this.#subscribers = filter(this.#subscribers, (s) => s !== callback);
    };
  }

  /**
   * Called when current user changes state or data
   */
  #handleChange = () => {
    forEach(this.#subscribers, (callback) => callback(this));
  };

  /**
   * Downloads all localizations registered via `register` function.
   * Filters only localizations related to the current locale.
   */
  async fetchLocalizations() {
    // Get files related to the current localizations
    const currentFiles = get(this.#localizations, this.locale, []);
    // Fetch all the files via dynamic import
    const files = await Promise.all(map(currentFiles, (file) => file()));
    for (const file of files) {
      // Append existing messages
      this.#messages = merge({}, this.#messages, file?.default ?? {});
    }
    // Log
    this.#log.info("🏳️‍", this.locale, { messages: this.#messages });
    // Notify subscribers
    this.#handleChange();
  }

  /**
   * Returns current language
   * @return {Language}
   */
  get current() {
    return get(this.#languages, this.locale, {});
  }

  /**
   * Returns current locale
   * @return {string}
   */
  get locale() {
    // Get user's locale from cache or detect browser locale or end with fallback
    const locale =
      this.#cache.retrieve("Locale") ??
      window?.navigator?.language ??
      this.#fallback;
    // Locale must be available
    return has(this.#languages, locale) ? locale : this.#fallback;
  }

  /**
   * Set's current locale
   * @param {string} locale
   */
  async setLocale(locale) {
    this.#cache.store("Locale", locale);
    await this.fetchLocalizations();
  }

  /**
   * Returns current message
   */
  get messages() {
    return this.#messages;
  }

  /**
   * Returns all languages
   * @return {Object}
   */
  get languages() {
    return this.#languages;
  }

  /**
   * Returns array of available languages codes
   * @return {[string]}
   */
  get availableLanguages() {
    return this.#availableLanguages;
  }

  /**
   * Returns fallback language code
   * @return {string}
   */
  get fallbackLanguage() {
    return toUpper(this.#fallback);
  }

  /**
   * Returns language code based on the given locale
   * @param locale {string}
   * @return {string}
   */
  #getCode = ({ locale }) => {
    return toUpper(first(split(locale, "_")));
  };

  /**
   * Returns string from the localized string based on the given language
   * @param localizedString {array}
   * @param language {string}
   * @return {string|null}
   */
  getLocalizedString(localizedString, language = this.current?.code) {
    return find(localizedString, ({ code }) => code === language)?.value;
  }

  /**
   * Call the register localizations under given ID
   * @param id {string} Unique ID of the localizations set
   * @param localizations {[{ locale: string, file: function }]}
   */
  register(id, localizations = []) {
    // Add all files to the localizations bag based on locale
    forEach(localizations, ({ locale, file }) => {
      this.#localizations = {
        ...this.#localizations,
        [locale]: [...get(this.#localizations, locale, []), file],
      };
    });
  }
}
