// React
import React, { isValidElement, cloneElement } from "react";
import PropTypes from "prop-types";
// Framework
import {
  fade,
  makeStyles,
  Typography,
  Button,
  TooltipButton,
  Box,
  Cloak,
} from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0, 2),
    color: theme.palette.action.active,
    borderColor: fade(theme.palette.action.active, 0.6),
    background: theme.palette.common.white,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SceneTabsOption = ({
  context,
  value,
  type = "icon",
  title,
  icon,
  disabled,
  selected,
  component,
  onSelection,
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    component ?? (
      <>
        {type === "icon" && (
          <Typography color="textSecondary" component="div">
            <Box color={selected ? "primary.main" : "action.active"}>
              <TooltipButton
                context={context}
                value={value}
                color="inherit"
                tooltip={title}
                onClick={onSelection}
                disabled={disabled}
              >
                {isValidElement(icon) &&
                  cloneElement(icon, {
                    color: "inherit",
                  })}
              </TooltipButton>
            </Box>
          </Typography>
        )}
        {type === "button" && (
          <Button
            context={context}
            value={value}
            variant="outlined"
            disabled={disabled}
            className={classes.button}
            onClick={onSelection}
          >
            {title}
          </Button>
        )}
        <Cloak enabled={!selected}>
          <Box width="100%" height={4} mt={0.5} bgcolor="primary.main" />
        </Cloak>
      </>
    )
  );
};

SceneTabsOption.propTypes = {
  type: PropTypes.oneOf(["icon", "button"]),
  title: PropTypes.string,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  onSelection: PropTypes.func,
  onClose: PropTypes.func,
};

export default SceneTabsOption;
