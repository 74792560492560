// React
import React, { useEffect, useState } from "react";
// Helpers
import { isNil } from "@mefisto/utils";
// Framework
import { makeStyles, LinearProgress } from "@material-ui/core";
import { useTheme } from "theme";
import { classnames } from "ui/classnames";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  linearColorPrimary: {
    backgroundColor: theme.palette.grey[200],
  },
  linearBarColorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  hidden: {
    visibility: "hidden",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Activity = ({ delay }) => {
  // Styles
  const classes = useStyles();
  const theme = useTheme();
  const timeout = isNil(delay) ? theme.transitions.duration.complex : delay;
  // State
  const [hidden, setHidden] = useState(true);
  // Effect
  useEffect(() => {
    const handleTimeout = () => setHidden(false);
    const unsubscribe = setTimeout(handleTimeout, timeout);
    return () => clearTimeout(unsubscribe);
  }, [timeout]);
  // Render
  return (
    <LinearProgress
      style={{ height: 2 }}
      variant={hidden ? "determinate" : "indeterminate"}
      value={0}
      classes={{
        root: classnames(hidden && classes.hidden),
        colorPrimary: classes.linearColorPrimary,
        barColorPrimary: classes.linearBarColorPrimary,
      }}
    />
  );
};

export default Activity;
