// React
import React, { useContext } from "react";
import PropTypes from "prop-types";
// Helpers
import { isArray, values } from "@mefisto/utils";
// Framework
import { usePortal } from "stack/core";
// Components
import { PluginContext } from "plugin/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Plugin = ({ id, entities, children, ...props }) => {
  // Framework
  const { log } = usePortal();
  // Context
  const { plugins } = useContext(PluginContext);
  if (plugins[id]) {
    log.warning(
      `${id} plugin was already imported. Check you plugins for duplicates.`
    );
  }
  plugins[id] = {
    id,
    entities: isArray(entities) ? entities : values(entities),
    ...props,
  };
  // Render
  return <>{children}</>;
};

Plugin.propTypes = {
  id: PropTypes.string.isRequired,
  localizations: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      file: PropTypes.func.isRequired,
    })
  ),
  entities: PropTypes.any,
  preferences: PropTypes.object,
  endpoint: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.node,
};

export default Plugin;
