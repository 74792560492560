// React
import React, { memo } from "react";
import PropTypes from "prop-types";
// Framework
import { useTranslate } from "localization/hooks";
import { classnames } from "ui/classnames";
import {
  makeStyles,
  fade,
  Box,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { SentimentVeryDissatisfiedTwoTone as ErrorIcon } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
  cover: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    transform: "none",
    zIndex: theme.zIndex.spinner,
    backgroundColor: theme.palette.common.white,
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: fade(theme.palette.primary.light, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.light, 0.25),
    },
    "&:active": {
      boxShadow: theme.shadows[0],
    },
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ErrorPlaceholder = ({
  display = "default",
  position = "default",
  icon,
  title,
  subtitle,
  actionTitle,
  onAction,
}) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { translate } = useTranslate();
  // Render
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      spacing={3}
      className={classnames({
        [classes.cover]: display === "cover",
        [classes.center]: position === "center",
      })}
    >
      <Grid item>{icon ?? <ErrorIcon color="error" fontSize="large" />}</Grid>
      <Grid item>
        <Typography
          variant="subtitle2"
          component="div"
          color="textSecondary"
          align="center"
        >
          {title ?? translate("core:ui.errorPlaceholder.title")}
        </Typography>
        {subtitle && (
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            align="center"
            gutterBottom
          >
            {subtitle}
          </Typography>
        )}
      </Grid>
      {onAction && (
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={onAction}
            className={classes.button}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              {actionTitle ?? translate("core:ui.errorPlaceholder.action")}
            </Box>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

ErrorPlaceholder.propTypes = {
  display: PropTypes.oneOf(["default", "cover"]),
  position: PropTypes.oneOf(["default", "center"]),
  icon: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action: PropTypes.string,
  onAction: PropTypes.func,
};

export default memo(ErrorPlaceholder);
