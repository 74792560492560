// React
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
// Framework
import { classnames } from "ui/classnames";
import { Section } from "ui/section";
import { UserInteraction } from "analytics";
import { makeStyles, Box, Button as CoreButton } from "@material-ui/core";
// Components
import Spinner from "../Spinner";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  label: {
    position: "relative",
    whiteSpace: "nowrap",
  },
  hideText: {
    color: "transparent !important",
  },
  danger: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  spinner: {
    width: "100%",
    top: 0,
    left: 0,
    transform: "unset",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  className,
  disabled,
  danger,
  loading,
  spinnerProps,
  children,
  ...props
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <CoreButton
      className={className}
      classes={{
        root: classnames({
          [classes.hideText]: loading,
          [classes.danger]: danger,
        }),
        label: classes.label,
      }}
      disabled={disabled || loading}
      {...props}
    >
      {loading && (
        <Box position="absolute" height="100%">
          <Spinner size="small" {...spinnerProps} />
        </Box>
      )}
      {children}
    </CoreButton>
  );
};

const Button = forwardRef(
  ({ context = "button", value, children, ...props }, ref) => (
    <UserInteraction
      ref={ref}
      element="button"
      value={value}
      component={Component}
      componentProps={props}
    >
      <Section context={context} value={value}>
        {children}
      </Section>
    </UserInteraction>
  )
);

// noinspection JSValidateTypes
Button.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string,
  /**
   * Button type
   */
  type: PropTypes.oneOfType([
    PropTypes.oneOf(["button", "reset", "submit"]),
    PropTypes.string,
  ]),
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  /**
   * The component used for the root node.
   * Either a string to use an HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the button will be in a danger state.
   */
  danger: PropTypes.bool,
  /**
   * If `true`, the button will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, no elevation is used.
   */
  disableElevation: PropTypes.bool,
  /**
   * If `true`, the  keyboard focus ripple will be disabled.
   */
  disableFocusRipple: PropTypes.bool,
  /**
   * If `true`, the ripple effect will be disabled.
   *
   * ⚠️ Without a ripple there is no styling for :focus-visible by default. Be sure
   * to highlight the element by applying separate styles with the `focusVisibleClassName`.
   */
  disableRipple: PropTypes.bool,
  /**
   * Element placed after the children.
   */
  endIcon: PropTypes.node,
  /**
   * If `true`, the button will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,
  /**
   * The URL to link to when the button is clicked.
   * If defined, an `a` element will be used as the root node.
   */
  href: PropTypes.string,
  /**
   * When set to `true` the button is in the loading state.
   * Spinner is visible and the button is disabled.
   * The button doesn't change its size. In other words,
   * the text remains, but it's hidden.
   */
  loading: PropTypes.bool,
  /**
   * The size of the button.
   * `small` is equivalent to the dense button styling.
   */
  size: PropTypes.oneOf(["large", "medium", "small"]),
  /**
   * Props passed to Spinner component visible when
   * the `loading` prop is set to true.
   */
  spinnerProps: PropTypes.object,
  /**
   * Element placed before the children.
   */
  startIcon: PropTypes.node,
  /**
   * The variant to use.
   */
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  /**
   * The content of the button.
   */
  children: PropTypes.node,
};

export default Button;
