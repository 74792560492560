// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { isNil } from "@mefisto/utils";
// Framework
import { classnames } from "ui/classnames";
import {
  makeStyles,
  Grid,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { useTheme } from "theme";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  top: {
    paddingTop: theme.spacing(2),
  },
  middle: {
    padding: theme.spacing(2),
  },
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
  bottom: {
    paddingBottom: theme.spacing(2),
  },
  progress: {
    display: "flex",
    position: "relative",
    lineHeight: "normal",
  },
  indeterminate: ({ color }) => ({
    color,
    position: "absolute",
    animationDuration: "550ms",
    left: 0,
  }),
  determinate: {
    color: theme.palette.grey[200],
  },
}));

const positionClass = (position, classes) => {
  switch (position) {
    case "top":
      return classes.top;
    case "middle":
      return classes.middle;
    case "center":
    case "modal":
      return classes.center;
    case "bottom":
      return classes.bottom;
    default:
      return "";
  }
};

const sizeClass = {
  tiny: 20,
  small: 24,
  normal: 48,
  large: 72,
};

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Spinner = ({
  size = "normal",
  running = true,
  title,
  progress,
  color,
  position,
  className,
}) => {
  // Styles
  const theme = useTheme();
  const classes = useStyles({
    color: color || theme.palette.primary.main,
  });
  let variant;
  if (!isNil(progress)) {
    variant = "determinate";
  } else if (running) {
    variant = "indeterminate";
  } else {
    variant = "static";
  }
  // Render
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={1}
      className={classnames(className, positionClass(position, classes))}
    >
      <Grid item>
        <div className={classes.progress}>
          <CircularProgress
            variant="determinate"
            value={100}
            className={classes.determinate}
            size={sizeClass[size]}
          />
          <CircularProgress
            variant={variant}
            value={progress}
            className={classes.indeterminate}
            disableShrink={isNil(progress) ? running : false}
            size={sizeClass[size]}
          />
        </div>
      </Grid>
      {title && (
        <Grid item>
          <Typography variant="subtitle2">{title}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

Spinner.propTypes = {
  running: PropTypes.bool,
  progress: PropTypes.number,
  color: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.oneOf(["tiny", "small", "normal", "large"]),
  position: PropTypes.oneOf(["top", "middle", "bottom", "center", "modal"]),
};

export default Spinner;
