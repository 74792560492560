// React
import React, {
  Children,
  cloneElement,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
// Helpers
import {
  get,
  has,
  intersection,
  isFunction,
  isObject,
  keys,
  reduce,
  size,
} from "@mefisto/utils";
// Framework
import { Section, CoverSpinner } from "ui";
import { useTranslate } from "localization";
import { Form, FormSubmitButton } from "form";
import { useDeepMemo } from "hooks";
import { useEntityRead, useEntityUpdate } from "model/hooks";
import { EntityPropType, getInput, getFiles } from "model/utils";
// Components
import ModelDialogButtons from "../ModelDialogButtons";
import ModelDialogContent from "../ModelDialogContent";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelDialogActionUpdate = ({
  context = "update",
  value,
  action,
  entity: Entity,
  schema,
  loading: customLoading,
  skeleton,
  onSubmit,
  onNext,
  onFinish,
  onClose,
  children,
  __options,
  __display,
}) => {
  // Props
  const {
    input,
    resources,
    languages,
    silent,
    optimisticUI,
    defaults = {},
    overrides = {},
    options = {},
  } = useDeepMemo(() => __options, [__options]);
  const {
    display = "individual",
    dataMapper,
    tab = 0,
    tabData,
    isLast = false,
    isLastTab = false,
    onNext: __onNext,
    onFinish: __onFinish,
    onClose: __onClose,
  } = useDeepMemo(() => __display ?? {}, [__display]);
  // Framework
  const { translate } = useTranslate();
  // Creates payload
  const getPayload = useCallback(
    ({ data, formData, form, tabData, loading }) => ({
      action,
      input,
      resources,
      languages,
      defaults,
      options,
      optimisticUI,
      form,
      loading,
      ...(data && { data }),
      ...(formData && { formData }),
      ...(tabData && { tabData }),
    }),
    [action, input, resources, languages, defaults, options, optimisticUI]
  );
  // Model
  const entityRead = useEntityRead(Entity, {
    fetchPolicy: "cache-first",
    input,
    resources,
    languages,
  });
  const entityUpdate = useEntityUpdate(Entity, {
    input,
    resources,
    languages,
    silent,
  });
  // Memo
  const entityReadData = useMemo(() => {
    const { data } = entityRead;
    return dataMapper ? dataMapper(data) : data;
  }, [entityRead, dataMapper]);
  const entityFormData = useMemo(() => {
    return reduce(
      entityReadData,
      (result, value, key) => {
        const isPaginated = isObject(value) && has(value, "data");
        if (isPaginated) {
          result[key] = get(value, "data", null);
        } else {
          result[key] = value;
        }
        return result;
      },
      {}
    );
  }, [entityReadData]);
  const loading = useMemo(() => {
    return entityUpdate.loading || customLoading;
  }, [entityUpdate, customLoading]);
  const finished = useMemo(() => {
    const { called, error, loading } = entityUpdate;
    if (called && loading && optimisticUI) {
      return true;
    } else {
      return called && !loading && !error;
    }
  }, [entityUpdate, optimisticUI]);
  // Callbacks
  const getContent = useCallback(
    ({ data, tabData, form }) => {
      const child = Children.toArray(children)[tab];
      return cloneElement(child, {
        ...getPayload({
          data,
          formData: form.values,
          tabData,
          form,
          loading,
        }),
      });
    },
    [children, getPayload, tab, loading]
  );
  const getSchema = useCallback(
    (data) => {
      return isFunction(schema)
        ? (formData, tabData) =>
            schema(getPayload({ data, formData, tabData, loading }))
        : schema;
    },
    [schema, getPayload, loading]
  );
  const errorCount = useCallback((form) => {
    return size(intersection(keys(form.errors), keys(form.touched)));
  }, []);
  // Handlers
  const handleSubmit = (formData) => {
    if (onSubmit) {
      onSubmit(
        getPayload({
          data: entityReadData,
          formData,
          tabData,
          loading,
        })
      );
    } else {
      const inputValues = getInput(formData, input);
      const fileValues = getFiles(formData);
      entityUpdate.update({
        input: inputValues,
        files: fileValues,
        optimistic: optimisticUI && {
          ...entityReadData,
          ...inputValues,
        },
      });
    }
  };
  const handleNext = useCallback(() => {
    __onNext?.();
    onNext?.();
  }, [__onNext, onNext]);
  const handleFinish = useCallback(
    (payload) => {
      __onFinish?.(payload);
      onFinish?.(payload);
    },
    [__onFinish, onFinish]
  );
  const handleClose = useCallback(() => {
    __onClose?.();
    onClose?.();
  }, [__onClose, onClose]);
  // Effects
  useEffect(() => {
    const { error } = entityRead;
    if (error) {
      handleClose();
    }
  }, [entityRead, handleClose]);
  useEffect(() => {
    if (finished) {
      handleFinish(getPayload({ data: entityUpdate.data, tabData }));
    }
  }, [finished, entityUpdate.data, tabData, getPayload, handleFinish]);
  // Render
  return (
    <Section context={context} value={value}>
      {entityRead.loading && <CoverSpinner size="small" />}
      {!entityRead.error && (
        <Form
          dialog
          schema={getSchema(entityReadData)}
          defaults={{
            // Default values before read is performed
            ...defaults,
            // Data after entity is read
            ...entityFormData,
            // Overrides, that will always be set
            ...overrides,
          }}
          disabled={loading}
          onSubmit={handleSubmit}
        >
          {(form) => (
            <>
              <ModelDialogContent display={display}>
                {getContent({ form, data: entityReadData, tabData })}
              </ModelDialogContent>
              <ModelDialogButtons
                skeleton={skeleton}
                loading={loading}
                errorCount={errorCount(form)}
                onClose={handleClose}
              >
                {display === "individual" && (
                  <FormSubmitButton
                    value="submit"
                    type="submit"
                    loading={!optimisticUI && loading}
                    finished={finished}
                  >
                    {translate("core:model.dialog.update.button.submit")}
                  </FormSubmitButton>
                )}
                {display === "group" && (
                  <>
                    {isLast ? (
                      <>
                        {isLastTab ? (
                          <FormSubmitButton
                            value="finish"
                            type="submit"
                            loading={!optimisticUI && loading}
                            finished={finished}
                          >
                            {translate(
                              "core:model.dialog.update.button.finish"
                            )}
                          </FormSubmitButton>
                        ) : (
                          <FormSubmitButton
                            value="next"
                            type="submit"
                            loading={!optimisticUI && loading}
                            finished={finished}
                          >
                            {translate("core:model.dialog.update.button.next")}
                          </FormSubmitButton>
                        )}
                      </>
                    ) : (
                      <FormSubmitButton
                        value="next"
                        type="button"
                        loading={!optimisticUI && loading}
                        finished={finished}
                        onClick={handleNext}
                      >
                        {translate("core:model.dialog.update.button.next")}
                      </FormSubmitButton>
                    )}
                  </>
                )}
              </ModelDialogButtons>
            </>
          )}
        </Form>
      )}
    </Section>
  );
};

ModelDialogActionUpdate.displayName = "ModelDialogActionUpdate";

ModelDialogActionUpdate.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  entity: EntityPropType,
  schema: PropTypes.any,
  loading: PropTypes.bool,
  skeleton: PropTypes.bool,
  onSubmit: PropTypes.func,
  onNext: PropTypes.func,
  onFinish: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default ModelDialogActionUpdate;
