// React
import React, { useMemo, useState, Suspense } from "react";
import PropTypes from "prop-types";
// Helpers
import { isObject } from "@mefisto/utils";
// Framework
import { makeStyles, ModalBackdrop } from "ui/components";
import { classnames } from "ui/classnames";
// Components
import ImageComponent from "./components/ImageComponent";
import Loader from "./components/Loader";
import ErrorBoundary from "./components/ErrorBoundary";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: ({ width, height, maxWidth, maxHeight }) => ({
    position: "relative",
    overflow: "hidden",
    width,
    height,
    maxWidth,
    maxHeight,
  }),
  image: ({ width, height, maxWidth, maxHeight, objectFit }) => ({
    width,
    height,
    maxWidth,
    maxHeight,
    objectFit,
  }),
  optimizeContrast: {
    imageRendering: "-webkit-optimize-contrast",
  },
  variantRounded: {
    borderRadius: theme.radius.small,
  },
  variantCircle: {
    borderRadius: theme.radius.rounded,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Image = ({
  image,
  width = "100%",
  height = "100%",
  objectFit = "cover",
  maxWidth,
  maxHeight,
  fallback,
  variant = "default",
  optimizeContrast,
  loader = <Loader />,
  containerProps,
  imageProps,
  onClick,
}) => {
  // Styles
  const classes = useStyles({ width, height, maxWidth, maxHeight, objectFit });
  // State
  const [blob, setBlob] = useState(null);
  const [backdrop, setBackdrop] = useState(false);
  // Memo
  const url = useMemo(() => {
    if (isObject(image)) {
      if (!image.url) {
        return null;
      }
      const url = new URL(image.url);
      if (url.protocol === "blob:") {
        setBackdrop(true);
        setBlob(url.toString());
      } else {
        setBackdrop(false);
      }
      if (blob) {
        return blob;
      }
      return url.toString();
    }
    return image;
  }, [image, blob]);
  // Render
  return (
    <div
      className={classnames(classes.root, {
        [classes.variantRounded]: variant === "rounded",
        [classes.variantCircle]: variant === "circle",
      })}
      {...containerProps}
    >
      <ErrorBoundary>
        <Suspense fallback={loader}>
          <ModalBackdrop open={backdrop} />
          {url ? (
            <ImageComponent
              srcList={url}
              className={classnames(classes.image, {
                [classes.optimizeContrast]: optimizeContrast,
              })}
              onClick={onClick}
              {...imageProps}
            />
          ) : (
            fallback
          )}
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

Image.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      generation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      url: PropTypes.string,
    }),
  ]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  objectFit: PropTypes.string,
  variant: PropTypes.oneOf(["default", "rounded", "circle"]),
  optimizeContrast: PropTypes.bool,
  loader: PropTypes.element,
  fallback: PropTypes.element,
};

export default Image;
