// React
import React, { Children } from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty, map, range } from "@mefisto/utils";
// Framework
import {
  makeStyles,
  GridList,
  GridListTile,
  ErrorPlaceholder,
  EmptyPlaceholder,
} from "ui/components";
import { useBreakpoint } from "ui/hooks";
import { classnames } from "ui/classnames";
import { InfiniteList } from "ui/list";
// Components
import SkeletonTile from "./components/SkeletonTile";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  gridList: {
    position: "relative",
    height: "100%",
    // Promote the list into his own layer on Chrome.
    // This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  gridListPadding: {
    padding: theme.spacing(1, 2, 2),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Collection = ({
  columns = 4,
  gap = 16,
  rowHeight = "auto",
  disablePadding,
  loading,
  error,
  hasMore,
  errorPlaceholderProps,
  emptyPlaceholderProps,
  skeletonProps,
  skeletonComponent,
  skeletonCount = 25,
  children,
  onLoadMore,
  onRefresh,
}) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { breakpoint } = useBreakpoint(columns);
  // Render
  return (
    <InfiniteList
      overflowX="hidden"
      overflowY={loading ? "hidden" : "auto"}
      isEmpty={Boolean(error || (!loading && isEmpty(children)))}
      hasMore={Boolean(error || loading ? false : hasMore)}
      loadMore={onLoadMore}
    >
      <GridList
        cols={breakpoint}
        spacing={gap}
        cellHeight={rowHeight}
        className={classnames(classes.gridList, {
          [classes.gridListPadding]: !disablePadding,
        })}
      >
        {error ? (
          <ErrorPlaceholder
            position="center"
            onAction={onRefresh}
            {...errorPlaceholderProps}
          />
        ) : loading ? (
          map(range(skeletonCount), (key) => (
            <GridListTile key={key}>
              {skeletonComponent ?? <SkeletonTile {...skeletonProps} />}
            </GridListTile>
          ))
        ) : isEmpty(children) ? (
          <EmptyPlaceholder {...emptyPlaceholderProps} />
        ) : (
          Children.map(children, (child) => (
            <GridListTile>{child}</GridListTile>
          ))
        )}
      </GridList>
    </InfiniteList>
  );
};

Collection.propTypes = {
  /**
   * Number of columns or object with number of columns
   * based on the current breakpoint.
   */
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      xs: PropTypes.number,
      sm: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
      xl: PropTypes.number,
    }),
  ]),
  /**
   * Gap between items
   */
  gap: PropTypes.number,
  /**
   * Cell height in px. Set to 'auto' to let the children determine the height.
   */
  rowHeight: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number]),
  /**
   * Set to true when collection data is loading
   */
  loading: PropTypes.bool,
  /**
   * Pass error object
   */
  error: PropTypes.object,
  /**
   * Set to `true` if collection has more items than currently displayed
   */
  hasMore: PropTypes.bool,
  /**
   * Pass `error placeholder` props
   */
  errorPlaceholderProps: PropTypes.object,
  /**
   * Pass `empty placeholder` props
   */
  emptyPlaceholderProps: PropTypes.object,
  /**
   * Pass `skeleton` props
   */
  skeletonProps: PropTypes.object,
  /**
   * Number of skeletons
   */
  skeletonCount: PropTypes.number,
  /**
   * Pass different skeleton component
   */
  skeletonComponent: PropTypes.element,
  /**
   * Items that will be in the image list.
   */
  children: PropTypes.node,
  /**
   * Called when collection is scrolled to `loadMore` area
   */
  onLoadMore: PropTypes.func,
  /**
   * Called when refresh is triggered
   */
  onRefresh: PropTypes.func,
};

export default Collection;
