// React
import React from "react";
// Framework
import { TooltipButton } from "ui";
import { useTranslate } from "localization/hooks";
import { TranslateRounded as LocalizationIcon } from "icon/material";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderButton = ({ onClick }) => {
  // Framework
  const { t } = useTranslate();
  // Render
  return (
    <TooltipButton
      tooltip={t("core:localization.headerItem.tooltip")}
      onClick={onClick}
    >
      <LocalizationIcon />
    </TooltipButton>
  );
};

export default HeaderButton;
