// React
import React from "react";
// Framework
import { usePortal, forwardType, App } from "@mefisto/web";
// Icon
import { PresentationIcon } from "icons";
// Loader
import { Loader } from "loader";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default forwardType(App, () => {
  // Framework
  const { config } = usePortal();
  const {
    appsPresentationEnabled = true,
    appsPresentationUrl = "",
  } = config.values;
  // Render
  return (
    <App
      visible
      name="PRESENTATION"
      type="NATIVE"
      color="#00b3e3"
      title="Home"
      priority={0}
      enabled={appsPresentationEnabled}
      path={appsPresentationUrl}
      icon={<PresentationIcon />}
      loader={<Loader />}
      // prettier-ignore
      navigation={{
        basename: "/",
        routes: {
          default: "/",
          notAuthenticated: "/",
        },
        paths: {
          home: "/",
          contact: "/contact",
          pricing: "/pricing",
          faq: "/faq",
          downloads: "/downloads",
          terms: "/terms-of-service",
          privacy: "/privacy-policy",
        },
      }}
      resource={{
        source: "ui",
      }}
    />
  );
});
