// React
import React, { forwardRef, useCallback } from "react";
// Framework
import {
  useUser,
  useTranslate,
  usePortal,
  TilePopover,
  TileHeader,
  TileOption,
  TileContent,
  TileFixedContent,
  TileActions,
  ArrowButton,
  Avatar,
  Box,
  Grid,
  Typography,
  Button,
} from "@mefisto/web";
// Icons
import { ArrowIcon, SettingsIcon } from "icons";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const AccountPopover = forwardRef(({ popoverProps }, ref) => {
  // Framework
  const { t } = useTranslate();
  const {
    user: {
      data: { name, email, profileImage },
    },
  } = useUser();
  const { authentication, navigation } = usePortal();
  // Callbacks
  const handleManage = useCallback(() => {
    ref.current.close();
    navigation.goTo(navigation.routes.profile);
  }, [ref, navigation]);
  const handleSettings = useCallback(() => {
    ref.current.close();
    navigation.goTo(navigation.routes.profile, {
      hash: { tab: "settings" },
    });
  }, [ref, navigation]);
  const handleSignOut = useCallback(async () => {
    authentication.signOut();
  }, [authentication]);
  // Render
  return (
    <TilePopover
      ref={ref}
      minWidth={300}
      maxWidth={340}
      popoverProps={popoverProps}
    >
      <TileContent>
        <TileHeader
          size="large"
          title={t("portal:component.account.headerItem.title")}
          options={[
            <TileOption
              value="settings"
              display="menu"
              title={t("portal:component.account.headerItem.option.settings")}
              icon={<SettingsIcon />}
              onSelection={handleSettings}
            />,
          ]}
        />
        <TileFixedContent>
          <ArrowButton
            fullWidth
            icon={<ArrowIcon color="action" />}
            onClick={handleManage}
          >
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justify="flex-start"
              spacing={3}
            >
              <Grid item>
                <Box ml={2}>
                  <Avatar title={name} image={profileImage} size={60} />
                </Box>
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="subtitle2"
                  component="div"
                  align="left"
                  lineHeight="normal"
                >
                  {name}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  component="div"
                  color="textSecondary"
                  align="left"
                  lineHeight="normal"
                >
                  {email}
                </Typography>
              </Grid>
            </Grid>
          </ArrowButton>
        </TileFixedContent>
      </TileContent>
      <TileActions>
        <Button
          value="signOut"
          size="small"
          color="primary"
          onClick={handleSignOut}
        >
          {t("portal:component.account.headerItem.button.signOut")}
        </Button>
      </TileActions>
    </TilePopover>
  );
});

export default AccountPopover;
