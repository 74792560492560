// React
import React from "react";
// Framework
import { usePortal, forwardType, App } from "@mefisto/web";
// Icon
import { AuthIcon } from "icons";
// Loader
import { Loader } from "loader";
// Navigation
import { paths as portalPaths } from "@mefisto/plugin-portal/navigation";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default forwardType(App, () => {
  // Framework
  const { config } = usePortal();
  const { appsAuthEnabled, appsAuthUrl } = config.values;
  // Render
  return (
    <App
      name="AUTH"
      type="NATIVE"
      enabled={appsAuthEnabled}
      title="Auth"
      priority={1}
      color="#00b3e3"
      path={appsAuthUrl}
      icon={<AuthIcon />}
      loader={<Loader />}
      navigation={{
        basename: "auth",
        routes: {
          default: "/sign-in",
        },
        paths: {
          ...portalPaths,
        },
      }}
    />
  );
});
