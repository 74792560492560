// React
import React from "react";
// Framework
import { Grid } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const FormLayout = ({
  direction = "column",
  alignItems = "stretch",
  spacing = 2,
  children,
}) => (
  <Grid
    container
    direction={direction}
    alignItems={alignItems}
    spacing={spacing}
  >
    {children}
  </Grid>
);

export default FormLayout;
