// React
import React, { isValidElement, cloneElement, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { compact, map, size } from "@mefisto/utils";
// Framework
import { classnames } from "ui/classnames";
import { makeStyles, TableCell, Grid } from "ui/components";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    // A small trick to size the cell based on the content
    width: 1,
    right: 0,
    color: theme.palette.action.active,
    backgroundColor: theme.palette.grey[100],
    whiteSpace: "nowrap",
  },
  transparent: {
    backgroundColor: "transparent",
  },
  hidden: {
    visibility: "hidden",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TableRowOptions = ({ index, highlighted, options }) => {
  // Styles
  const classes = useStyles();
  // Memo
  const children = useMemo(() => {
    return compact(options);
  }, [options]);
  // Render
  return (
    <TableCell
      className={classnames(classes.root, {
        [classes.transparent]: !highlighted,
      })}
    >
      {size(children) > 0 && (
        <Grid
          container
          wrap="nowrap"
          justify="flex-end"
          alignItems="center"
          className={classnames({
            [classes.hidden]: !highlighted,
          })}
        >
          {map(children, (child, key) => (
            <Grid item key={key}>
              {isValidElement(child) &&
                cloneElement(child, {
                  __renderProps: {
                    index,
                  },
                })}
            </Grid>
          ))}
        </Grid>
      )}
    </TableCell>
  );
};

TableRowOptions.propTypes = {
  highlighted: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.node),
};

export default TableRowOptions;
