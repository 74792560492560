// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { classnames, makeStyles } from "ui";
import { useNavigationLayout } from "navigation";
import { OfflineContent } from "layout/components";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    overflow: "auto",
    "-webkit-overflow-scrolling": "touch",
  },
  rootOpened: ({ openedWidth, openingTransition }) => ({
    width: `calc(100% - ${openedWidth}px)`,
    transition: openingTransition,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),
  rootClosed: ({ closedWidth, closingTransition }) => ({
    width: `calc(100% - ${closedWidth}px)`,
    transition: closingTransition,
    [theme.breakpoints.down("sm")]: {
      width: `100%`,
    },
  }),
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const DrawerLayoutScene = ({ children }) => {
  // Layout
  const {
    menuProps: {
      closed,
      openedWidth,
      closedWidth,
      openingTransition,
      closingTransition,
    } = {},
  } = useNavigationLayout();
  // Styles
  const classes = useStyles({
    openedWidth,
    closedWidth,
    openingTransition,
    closingTransition,
  });
  // Render
  return (
    <div
      className={classnames(classes.root, {
        [classes.rootOpened]: !closed,
        [classes.rootClosed]: closed,
      })}
    >
      <OfflineContent>{children}</OfflineContent>
    </div>
  );
};

DrawerLayoutScene.propTypes = {
  /**
   * Scene content
   */
  children: PropTypes.node.isRequired,
};

export default DrawerLayoutScene;
