// React
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { keys, isFunction } from "@mefisto/utils";
// Framework
import {
  classnames,
  makeStyles,
  Snackbar,
  Collapse,
  SnackbarContent,
} from "ui";
// Components
import { SNACKBAR_INDENTS, allClasses, REASONS } from "snackbar/utils";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  ...allClasses.mui,
  base: {
    borderRadius: theme.radius.large,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(0.5, 3, 0.5, 1.5),
    color: theme.palette.text.primary,
    background: theme.palette.common.white,
    ...theme.typography.subtitle2,
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  wrappedRoot: {
    position: "relative",
    transform: "translateX(0)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  collapseContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  collapseWrapper: {
    transition: theme.transitions.create(["margin-bottom"], { easing: "ease" }),
    marginTop: SNACKBAR_INDENTS.snackbar.default,
    marginBottom: SNACKBAR_INDENTS.snackbar.default,
  },
  collapseWrapperDense: {
    marginTop: SNACKBAR_INDENTS.snackbar.dense,
    marginBottom: SNACKBAR_INDENTS.snackbar.dense,
  },
}));

////////////////////////////////////////////////////
/// Utils
////////////////////////////////////////////////////

const DIRECTION = {
  right: "left",
  left: "right",
  bottom: "up",
  top: "down",
};

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SnackbarItem = ({
  snack,
  action,
  content,
  hideIconVariant,
  preventDuplicate,
  iconVariant,
  dense,
  ContentProps = {},
  TransitionProps: otherTransitionProps = {},
  onEntered,
  onExited,
  onClose,
  ...other
}) => {
  // Props
  const {
    action: contentAction,
    className,
    ...otherContentProps
  } = ContentProps;
  const {
    key,
    persist,
    children,
    content: singleContent,
    variant = "default",
    action: singleAction,
    ContentProps: singleContentProps = {},
    anchorOrigin,
    requestClose,
    entered,
    TransitionProps: singleTransitionProps = {},
    ...singleSnackProps
  } = snack;
  const contentProps = {
    ...otherContentProps,
    ...singleContentProps,
    action:
      singleAction || singleContentProps.action || contentAction || action,
  };
  const ariaDescribedby = contentProps["aria-describedby"] || "client-snackbar";
  // Style
  const classes = useStyles();
  // State
  const [collapsed, setCollapsed] = useState(true);
  const [timeout, setTimeout] = useState(null);
  // Callback
  const getTransitionDirection = useCallback((anchorOrigin) => {
    if (anchorOrigin.horizontal !== "center") {
      return DIRECTION[anchorOrigin.horizontal];
    }
    return DIRECTION[anchorOrigin.vertical];
  }, []);
  const getSnackbarClasses = useCallback((classes) => {
    // Filter classes object and return keys that are allowed in material-ui
    // snackbar classes prop
    const snackbarMuiClasses = keys(classes)
      .filter((key) => allClasses.mui[key] !== undefined)
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: classes[key],
        }),
        {}
      );
    return {
      ...snackbarMuiClasses,
      root: classnames(snackbarMuiClasses.root, classes.wrappedRoot),
    };
  }, []);
  const getCollapseClasses = useCallback(
    (classes, dense) => ({
      container: classes.collapseContainer,
      wrapper: classnames(classes.collapseWrapper, {
        [classes.collapseWrapperDense]: dense,
      }),
    }),
    []
  );
  // Handlers
  const handleClose = (key) => (event, reason) => {
    if (snack.onClose) {
      snack.onClose(event, reason, key);
    }
    onClose(event, reason, key);
  };
  const handleEntered = (key) => (node, isAppearing) => {
    if (snack.onEntered) {
      snack.onEntered(node, isAppearing, key);
    }
    onEntered(node, isAppearing, key);
    if (snack.requestClose) {
      handleClose(key)(null, REASONS.MAXSNACK);
    }
  };
  const handleExited = (key) => (event) => {
    if (snack.onExited) {
      snack.onExited(event, key);
    }
    onExited(event, key);
  };
  const handleExitedScreen = () => {
    const timeout = setTimeout(() => {
      setCollapsed((collapsed) => !collapsed);
    }, 125);
    setTimeout(timeout);
  };
  // Effects
  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeout]);
  // Render
  return (
    <Collapse
      unmountOnExit
      timeout={200}
      in={collapsed}
      classes={getCollapseClasses(classes, dense)}
      onExited={handleExited(key)}
    >
      <Snackbar
        {...other}
        {...singleSnackProps}
        open={snack.open}
        anchorOrigin={anchorOrigin}
        TransitionProps={{
          direction: getTransitionDirection(anchorOrigin),
          ...otherTransitionProps,
          ...singleTransitionProps,
          onExited: handleExitedScreen,
        }}
        classes={getSnackbarClasses(classes)}
        onClose={handleClose(key)}
        onEntered={handleEntered(key)}
      >
        <SnackbarContent
          className={classnames(classes.base, className)}
          {...contentProps}
          aria-describedby={ariaDescribedby}
          message={
            <span id={ariaDescribedby} className={classes.message}>
              {!hideIconVariant ? iconVariant[variant] : null}
              {snack.message}
            </span>
          }
          action={
            isFunction(contentProps.action)
              ? contentProps.action(key)
              : contentProps.action
          }
        />
      </Snackbar>
    </Collapse>
  );
};

SnackbarItem.propTypes = {
  classes: PropTypes.object.isRequired,
  snack: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    variant: PropTypes.oneOf([
      "default",
      "error",
      "success",
      "warning",
      "info",
    ]),
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    open: PropTypes.bool.isRequired,
    requestClose: PropTypes.bool.isRequired,
    entered: PropTypes.bool.isRequired,
  }).isRequired,
  iconVariant: PropTypes.shape({
    success: PropTypes.any.isRequired,
    warning: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    info: PropTypes.any.isRequired,
  }).isRequired,
  hideIconVariant: PropTypes.bool.isRequired,
  preventDuplicate: PropTypes.bool.isRequired,
  dense: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  onEntered: PropTypes.func.isRequired,
};

export default SnackbarItem;
