// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, Skeleton, Typography } from "ui/components";
import { Tile, TileContent, TileImage } from "ui/tile";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  root: {
    opacity: 0.4,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SkeletonTile = ({
  display = "border",
  height = 120,
  animation = false,
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Tile display={display} className={classes.root}>
      <TileImage height={height}>
        <Skeleton variant="rect" height={height} animation={animation} />
      </TileImage>
      <TileContent>
        <Typography variant="subtitle2">
          <Skeleton animation={animation} />
        </Typography>
      </TileContent>
    </Tile>
  );
};

SkeletonTile.propTypes = {
  display: PropTypes.string,
  height: PropTypes.number,
  animation: PropTypes.oneOf(["pulse", "wave", false]),
};

export default SkeletonTile;
