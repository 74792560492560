// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { classnames } from "ui/classnames";
import { makeStyles, CardContent } from "ui/components";
import { Section } from "ui/section";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  dense: {
    padding: theme.spacing(2),
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  gutter: {
    padding: theme.spacing(5),
    "&:last-child": {
      paddingBottom: theme.spacing(5),
    },
  },
  scrollable: {
    overflow: "scroll",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileContent = ({
  context = "content",
  value,
  gutter,
  dense,
  scrollable,
  className,
  children,
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Section context={context} value={value}>
      <CardContent
        className={classnames(classes.root, className, {
          [classes.dense]: dense,
          [classes.gutter]: gutter,
          [classes.scrollable]: scrollable,
        })}
      >
        {children}
      </CardContent>
    </Section>
  );
};

TileContent.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  dense: PropTypes.bool,
  gutter: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default TileContent;
