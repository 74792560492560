// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { Section, Box } from "ui";
// Components
import CoverTitle from "./components/CoverTitle";
import CoverSubtitle from "./components/CoverSubtitle";
import CoverProfileImage from "./components/CoverProfileImage";
import CoverTags from "./components/CoverTags";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Cover = ({
  context = "cover",
  value,
  title,
  titleProps,
  subtitle,
  subtitleProps,
  coverImage,
  profileImage,
  tags,
}) => (
  <Section context={context} value={value}>
    <Box position="relative">
      {coverImage}
      <Box bgcolor="common.white" pb={3}>
        <CoverTags tags={tags} />
        <CoverTitle title={title} titleProps={titleProps} />
        <CoverSubtitle subtitle={subtitle} subtitleProps={subtitleProps} />
      </Box>
      <CoverProfileImage profileImage={profileImage} />
    </Box>
  </Section>
);

Cover.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  titleProps: PropTypes.object,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitleProps: PropTypes.object,
  coverImage: PropTypes.node,
  profileImage: PropTypes.node,
  tags: PropTypes.arrayOf(PropTypes.node),
};

export default Cover;
