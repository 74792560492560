// React
import React, { useState } from "react";
// Helpers
import { isEmpty, get, toString } from "@mefisto/utils";
// Framework
import { useDeepMemo } from "hooks";
import { useTranslate } from "localization";
import { ClearOutlined as ClearIcon } from "icon/material";
import { NumberFormat } from "form/formatter";
import { Tooltip, IconButton, TextField, InputAdornment } from "ui";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  form,
  field,
  inputComponent,
  icon: Icon,
  disabled,
  label,
  helperText,
  placeholder,
  ...props
}) => {
  const { touched, errors, isSubmitting } = form;
  const { name, value } = field;
  const fieldError = get(errors, name);
  const showError = get(touched, name) && !!fieldError;
  // Framework
  const { t } = useTranslate();
  // State
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  // Handlers
  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);
  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);
  const handleClear = () => form.setFieldValue(name, "");
  const handleChange = (event) => {
    const { value } = event.target;
    form.setFieldValue(name, value);
  };
  return (
    <TextField
      fullWidth
      variant="outlined"
      name={name}
      value={value ?? ""}
      label={label}
      placeholder={placeholder}
      helperText={showError ? fieldError : helperText}
      error={showError}
      disabled={disabled ?? isSubmitting}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...(inputComponent && { inputComponent }),
        startAdornment: Icon ? (
          <InputAdornment position="start">
            <Icon color={showError ? "error" : "action"} fontSize="small" />
          </InputAdornment>
        ) : null,
        endAdornment: (
          <InputAdornment
            position="end"
            style={{
              visibility:
                (focus || hover) && !isEmpty(toString(value))
                  ? "visible"
                  : "hidden",
            }}
          >
            <Tooltip
              enterDelay={800}
              placement="bottom"
              title={t("core:form.textField.tooltip.clear")}
            >
              <IconButton onClick={handleClear} tabIndex={-1}>
                <ClearIcon color="action" fontSize="small" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onChange={handleChange}
      {...props}
    />
  );
};

const FormTextField = (props) => {
  const { format, ...rest } = props;
  // Memo
  const inputComponent = useDeepMemo(() => {
    if (format?.type === "number") {
      return NumberFormat({ ...format });
    } else {
      return format;
    }
  }, [format]);
  // Render
  return (
    <FormField
      component={Component}
      inputComponent={inputComponent}
      {...rest}
    />
  );
};

export default FormTextField;
