// React
import React from "react";
// Framework
import { Box } from "@material-ui/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const MultilineText = ({ children }) => (
  <Box whiteSpace="pre-line">{children}</Box>
);

export default MultilineText;
