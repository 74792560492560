// React
import React, { cloneElement, useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { isUndefined } from "@mefisto/utils";
// Framework
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Cloak,
  Divider,
  Grid,
  Box,
} from "ui/components";
import { useTheme } from "theme";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileOption = ({
  context = "option",
  value,
  display,
  component,
  divider,
  selected,
  title,
  subtitle,
  icon,
  color,
  onSelection,
  onClose,
}) => {
  // Styles
  const { radius } = useTheme();
  // Handlers
  const handleSelection = useCallback(() => {
    onClose && onClose();
    onSelection && onSelection();
  }, [onSelection, onClose]);
  // Render
  return (
    <>
      {component ?? (
        <>
          {display === "menu" && (
            <>
              {divider ? (
                <Box py={1}>
                  <Divider variant="middle" />
                </Box>
              ) : (
                <MenuItem
                  dense
                  context={context}
                  value={value}
                  onClick={handleSelection}
                >
                  {icon && (
                    <ListItemIcon>
                      {cloneElement(icon, { fontSize: "small", color })}
                    </ListItemIcon>
                  )}
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid item>
                      <ListItemText
                        primary={title}
                        secondary={subtitle}
                        color={color}
                        primaryTypographyProps={{ color }}
                        secondaryTypographyProps={{ color }}
                      />
                    </Grid>
                    {!isUndefined(selected) && (
                      <Grid item>
                        <Cloak enabled={!selected}>
                          <Box
                            width={8}
                            height={8}
                            borderRadius={radius.rounded}
                            bgcolor="info.main"
                          />
                        </Cloak>
                      </Grid>
                    )}
                  </Grid>
                </MenuItem>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

TileOption.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  display: PropTypes.oneOf(["standalone", "menu"]).isRequired,
  divider: PropTypes.bool,
  selected: PropTypes.bool,
  component: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.element,
  onSelection: PropTypes.func,
  onClose: PropTypes.func,
};

export default TileOption;
