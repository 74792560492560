// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { classnames, makeStyles, Button } from "ui";
import { useNavigationLayout } from "navigation";
import { MenuOpenRounded as ArrowIcon } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: ({ height }) => ({
    borderTop: `2px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.radius.none,
    height,
  }),
  label: {
    justifyContent: "flex-end",
  },
  endIcon: {
    transition: theme.transitions.create(["transform", "margin"]),
    marginRight: theme.spacing(1),
  },
  endIconClose: {
    marginRight: theme.spacing(3.5),
    transform: "rotate(180deg)",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const DrawerLayoutMenuToggle = ({ closed, onClosed }) => {
  // Layout
  const { menuProps: { baselineHeight } = {} } = useNavigationLayout();
  // Styles
  const classes = useStyles({
    height: baselineHeight,
  });
  // Render
  return (
    <Button
      fullWidth
      value="toggle"
      endIcon={<ArrowIcon color="action" />}
      classes={{
        root: classes.root,
        label: classes.label,
        endIcon: classnames(classes.endIcon, {
          [classes.endIconClose]: closed,
        }),
      }}
      onClick={onClosed}
    />
  );
};

DrawerLayoutMenuToggle.propTypes = {
  /**
   * Set to `true` when the menu is closed
   */
  closed: PropTypes.bool,
  /**
   * Called when user taps the toggle.
   */
  onClosed: PropTypes.func,
};

export default DrawerLayoutMenuToggle;
