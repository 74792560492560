// React
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
// Helpers
import { isString } from "@mefisto/utils";
// Framework
import {
  classnames,
  makeStyles,
  Fade,
  Section,
  Container,
  Scrollable,
} from "ui";
import { useNavigationLayout } from "navigation";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    height: "100%",
  },
  container: {
    height: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    margin: "auto",
  },
  fill: {
    position: "fixed",
    inset: 0,
    margin: 0,
  },
  fillAvailable: {
    flex: 1,
  },
  verticalFill: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  disableGutters: {
    padding: 0,
  },
  disableVerticalGutters: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  disableHorizontalGutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Scene = forwardRef(({ children, ...sceneProps }, ref) => {
  // Framework
  const {
    sceneProps: {
      context = "scene",
      value,
      fill,
      fillAvailable,
      verticalFill = false,
      horizontalFill = "lg",
      horizontalFillFixed,
      disableGutters,
      appendComponent,
      prependComponent,
      contentGutterStart,
      contentGutterEnd,
      contentGutterTop,
      contentGutterBottom,
      scrollTo,
      onScroll,
    },
  } = useNavigationLayout({ sceneProps });
  // Styles
  const classes = useStyles();
  // Render
  return (
    <Section context={context} value={value}>
      <Scrollable
        ref={ref}
        scrollTo={scrollTo}
        trackStyle={{ paddingTop: contentGutterTop }}
        onScroll={onScroll}
      >
        <div className={classes.root}>
          {prependComponent}
          <Fade in>
            <div
              className={classnames({
                [classes.fill]: fill,
                [classes.fillAvailable]: fillAvailable,
                [classes.verticalFill]: verticalFill,
              })}
            >
              <Container
                fixed={horizontalFillFixed}
                maxWidth={
                  fill
                    ? false
                    : isString(horizontalFill)
                    ? horizontalFill
                    : !horizontalFill
                }
                classes={{ root: classes.container }}
                className={classnames({
                  [classes.disableGutters]: disableGutters === true,
                  [classes.disableVerticalGutters]:
                    disableGutters === "vertical",
                  [classes.disableHorizontalGutters]:
                    disableGutters === "horizontal",
                })}
              >
                <div
                  className={classes.content}
                  style={{
                    paddingTop: contentGutterTop,
                    paddingBottom: contentGutterBottom,
                    paddingLeft: contentGutterStart,
                    paddingRight: contentGutterEnd,
                  }}
                >
                  {children}
                </div>
              </Container>
            </div>
          </Fade>
          {appendComponent}
        </div>
      </Scrollable>
    </Section>
  );
});

Scene.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string,
  /**
   * So to `true` to let the scene fill the whole vertical and horizontal space.
   */
  fill: PropTypes.bool,
  /**
   * So to `true` to let the scene fill the whole vertical space.
   */
  verticalFill: PropTypes.bool,
  /**
   * Determine the fill size of the container.
   * The container width grows with the size of the screen.
   * Set to `true` to let the container fill the whole screen.
   */
  horizontalFill: PropTypes.oneOf([true, false, "lg", "md", "sm", "xl", "xs"]),
  /**
   * Set the max-width to match the min-width of the current breakpoint.
   * This is useful if you'd prefer to design for a fixed set of sizes
   * instead of trying to accommodate a fully fluid viewport.
   * It's fluid by default.
   */
  horizontalFillFixed: PropTypes.bool,
  /**
   * Margin added to scene.
   */
  margin: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  /**
   * Removes `vertical` or `horizontal` gutters.
   * If `true`, all gutters are removed.
   */
  disableGutters: PropTypes.oneOf([true, "vertical", "horizontal"]),
  /**
   * Component added before scene children
   */
  appendComponent: PropTypes.node,
  /**
   * Component added after scene children
   */
  prependComponent: PropTypes.node,
  /**
   * Callback called whenever the scene is scrolled
   */
  onScroll: PropTypes.func,
  /**
   * Children passed to scene
   */
  children: PropTypes.node,
};

export default Scene;
