// React
import React, { useMemo } from "react";
import PropTypes from "prop-types";
// Framework
import { classnames } from "ui/classnames";
import { makeStyles, Grid, Box, Typography } from "ui/components";
import { Section } from "ui/section";
// Components
import TileOptions from "./components/TileOptions";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    marginBottom: 0,
  },
  titleDefaultSize: {
    fontSize: theme.typography.pxToRem(16),
  },
  titleLargeSize: {
    margin: theme.spacing(2, 0),
  },
  flex: {
    flex: 1,
  },
  transparent: {
    position: "absolute",
    display: "flex",
    justifyContent: "flex-end",
    top: 0,
    right: theme.spacing(2),
    height: "auto",
    padding: theme.spacing(1),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileHeader = ({
  context = "header",
  value,
  display = "permanent",
  size = "default",
  title,
  searchBar,
  options,
  hover,
  className,
}) => {
  // Styles
  const classes = useStyles();
  // Memo
  const visibility = useMemo(() => {
    if (display === "permanent") {
      return "visible";
    }
    return hover === "true" ? "visible" : "hidden";
  }, [hover, display]);
  // Render
  return (
    <Section context={context} value={value}>
      <Box visibility={visibility}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          wrap="nowrap"
          className={classnames(classes.root, className, {
            [classes.transparent]: display === "transparent",
          })}
        >
          <Grid item className={classes.flex}>
            {title && (
              <Typography
                className={classnames({
                  [classes.titleDefaultSize]: size === "default",
                  [classes.titleLargeSize]: size === "large",
                })}
                variant={size === "large" ? "h6" : "subtitle2"}
                component="div"
                display="block"
                color="textPrimary"
              >
                {title}
              </Typography>
            )}
            {searchBar}
          </Grid>
          {options && (
            <Grid item>
              <Box mr={-2}>
                <TileOptions display={display} options={options} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Section>
  );
};

TileHeader.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  display: PropTypes.oneOf(["permanent", "transparent"]),
  size: PropTypes.oneOf(["default", "large"]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element,
  ]),
  searchBar: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.node),
};

export default TileHeader;
