// React
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { map, isEmpty, isFunction, compact } from "@mefisto/utils";
// Framework
import { classnames } from "ui/classnames";
import { InfiniteList } from "ui/list";
import { makeStyles } from "ui/components";
import { Table as TableComponent } from "@material-ui/core";
// Components
import TableBody from "./components/TableBody";
import TableRowSkeleton from "./components/TableRowSkeleton";
import TableHeader from "../TableHeader";
import TableContext from "../TableContext";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  wrapper: {
    position: "relative",
    overflowX: "auto",
    height: "100%",
  },
  tableEmpty: {
    height: "100%",
  },
  tableWidth: {
    minWidth: theme.spacing(50),
    height: "100%",
  },
  loading: {
    minHeight: 200,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Table = ({
  data,
  hasMore,
  direction,
  orderBy,
  loading,
  error,
  columns,
  emptyTitle,
  emptySubtitle,
  disableHeader,
  skeletonX = 4,
  skeletonY = 20,
  children,
  onRefresh,
  onLoadMore,
  onSortChange,
}) => {
  // Styles
  const classes = useStyles();
  // Memo
  const rows = useMemo(() => {
    return compact(
      map(data, (data, index) => {
        if (children) {
          return isFunction(children) ? children({ data, index }) : children;
        }
      })
    );
  }, [data, children]);
  const hasRows = useMemo(() => {
    return !isEmpty(rows);
  }, [rows]);
  // Handlers
  const handleSort = useCallback(
    (property) => {
      onSortChange &&
        onSortChange(
          property,
          orderBy === property && direction === "DESC" ? "ASC" : "DESC"
        );
    },
    [onSortChange, orderBy, direction]
  );
  // Render
  return (
    <TableContext data={data}>
      <div className={classes.root}>
        <div
          className={classnames(classes.wrapper, {
            [classes.loading]: loading || error,
          })}
        >
          {loading && (
            <TableRowSkeleton
              header={!disableHeader}
              x={skeletonX}
              y={skeletonY}
            />
          )}
          <InfiniteList
            isEmpty={!hasRows}
            loadMore={onLoadMore}
            hasMore={loading || error ? false : hasMore}
          >
            <TableComponent
              stickyHeader
              className={classnames({
                [classes.tableEmpty]: !hasRows,
                [classes.tableWidth]: hasRows,
              })}
            >
              {!disableHeader && hasRows && (
                <TableHeader
                  columns={columns}
                  direction={direction}
                  orderBy={orderBy}
                  onSort={handleSort}
                />
              )}
              <TableBody
                rows={rows}
                data={data}
                loading={loading}
                error={error}
                emptyTitle={emptyTitle}
                emptySubtitle={emptySubtitle}
                onRefresh={onRefresh}
              />
            </TableComponent>
          </InfiniteList>
        </div>
      </div>
    </TableContext>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  hasMore: PropTypes.bool,
  direction: PropTypes.string,
  orderBy: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.object,
  columns: PropTypes.array,
  emptyTitle: PropTypes.string,
  emptySubtitle: PropTypes.string,
  disableHeader: PropTypes.bool,
  skeletonX: PropTypes.number,
  skeletonY: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onRefresh: PropTypes.func,
  onLoadMore: PropTypes.func,
  onSortChange: PropTypes.func,
};

export default Table;
