// React
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty } from "@mefisto/utils";
// Framework
import { useTranslate } from "localization/hooks";
import { makeStyles, InputBase, IconButton } from "ui/components";
import { Search as SearchIcon, Close as ClearIcon } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    borderRadius: theme.radius.large,
    background: theme.palette.grey[200],
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  clearIcon: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    width: "100%",
    padding: theme.spacing(1.25, 5, 1.25, 6),
    transition: theme.transitions.create("width"),
    color: theme.palette.text.primary,
    ...theme.typography.subtitle2,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Search = ({ autoFocus, delay = 250, onChange }) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { t } = useTranslate();
  // State
  const [value, setValue] = useState("");
  // Callbacks
  const change = useCallback(onChange, [onChange]);
  // Effect
  useEffect(() => {
    const handleTimeout = () => change(value);
    const timeout = setTimeout(handleTimeout, delay);
    return () => clearTimeout(timeout);
  }, [change, delay, value]);
  // Handlers
  const handleChange = (event) => {
    const { value } = event.target;
    setValue(value);
  };
  const handleClear = () => {
    setValue("");
  };
  // Render
  return (
    <div className={classes.root}>
      <div className={classes.searchIcon}>
        <SearchIcon color="action" />
      </div>
      <InputBase
        autoFocus={autoFocus}
        placeholder={t("core:ui.dialog.entity.search.placeholder")}
        value={value}
        onChange={handleChange}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
      />
      {!isEmpty(value) && (
        <div className={classes.clearIcon}>
          <IconButton tabIndex={-1} onClick={handleClear}>
            <ClearIcon fontSize="small" color="action" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

Search.propTypes = {
  autoFocus: PropTypes.bool,
  delay: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default Search;
