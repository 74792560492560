// React
import React, { cloneElement } from "react";
import PropTypes from "prop-types";
// Helpers
import { reduce, reverse } from "@mefisto/utils";
// Framework
import { useLazy } from "hooks";
// Context
import { PortalContext } from "./PortalContext";
// Components
import { useStackContext } from "../useStackContext";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const PortalProvider = ({
  dependencies: {
    Activity,
    Agent,
    Analytics,
    Apps,
    Authentication,
    Authorization,
    Cache,
    Config,
    Externals,
    Firebase,
    Foggle,
    Format,
    Interceptor,
    Localization,
    Log,
    Messaging,
    Navigation,
    Performance,
    Permission,
    PWA,
    Resource,
    ServiceWorker,
    Snackbar,
    Storage,
    Theme,
    Time,
    UI,
    UploadQueue,
  },
  providers,
  children,
}) => {
  // Construct dependencies
  const activity = useLazy(Activity);
  const agent = useLazy(Agent);
  const analytics = useLazy(Analytics);
  const apps = useLazy(Apps);
  const authentication = useLazy(Authentication);
  const authorization = useLazy(Authorization);
  const cache = useLazy(Cache);
  const config = useLazy(Config);
  const externals = useLazy(Externals);
  const firebase = useLazy(Firebase);
  const foggle = useLazy(Foggle);
  const format = useLazy(Format);
  const interceptor = useLazy(Interceptor);
  const localization = useLazy(Localization);
  const log = useLazy(Log);
  const messaging = useLazy(Messaging);
  const navigation = useLazy(Navigation);
  const performance = useLazy(Performance);
  const permission = useLazy(Permission);
  const pwa = useLazy(PWA);
  const resource = useLazy(Resource);
  const serviceWorker = useLazy(ServiceWorker);
  const snackbar = useLazy(Snackbar);
  const storage = useLazy(Storage);
  const theme = useLazy(Theme);
  const time = useLazy(Time);
  const ui = useLazy(UI);
  const uploadQueue = useLazy(UploadQueue);
  // Initialize context
  const context = useStackContext({
    activity,
    agent,
    analytics,
    apps,
    authentication,
    authorization,
    cache,
    config,
    externals,
    firebase,
    foggle,
    format,
    interceptor,
    localization,
    log,
    messaging,
    navigation,
    performance,
    permission,
    pwa,
    resource,
    serviceWorker,
    snackbar,
    storage,
    theme,
    time,
    ui,
    uploadQueue,
  });
  // Render
  return (
    <PortalContext.Provider value={context}>
      {reduce(
        reverse(providers),
        (result, provider) => {
          return cloneElement(provider, {
            children: result ?? children,
          });
        },
        null
      )}
    </PortalContext.Provider>
  );
};

PortalProvider.propTypes = {
  dependencies: PropTypes.object,
  providers: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.node,
};

export { PortalProvider };
