// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TimeAgo = ({ timestamp, timeout = 15000, children }) => {
  // Framework
  const { time } = usePortal();
  // State
  const [value, setValue] = useState(time.timeAgo(timestamp));
  // Effects
  useEffect(() => {
    const handleInterval = () => setValue(time.timeAgo(timestamp));
    const interval = setInterval(handleInterval, timeout);
    handleInterval();
    return () => clearInterval(interval);
  }, [time, timeout, timestamp]);
  // Render
  return <React.Fragment>{children(value)}</React.Fragment>;
};

TimeAgo.propTypes = {
  timestamp: PropTypes.number.isRequired,
  timeout: PropTypes.number,
};

export default TimeAgo;
