// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, Fade, Popover as MuiPopover } from "@material-ui/core";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: ({ offset }) => ({
    margin: theme.spacing(
      offset.top || 0,
      offset.right || 0,
      offset.bottom || 0,
      offset.left || 0
    ),
  }),
  paper: {
    border: "none",
    borderRadius: theme.radius.large,
    boxShadow: theme.shadows[2],
    overflow: "hidden",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Popover = ({
  open,
  anchorEl,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "right",
  },
  offset = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  onClose,
  children,
  ...rest
}) => {
  // Styles
  const classes = useStyles({ offset });
  // Render
  return (
    <MuiPopover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      TransitionComponent={Fade}
      className={classes.root}
      classes={{ paper: classes.paper }}
      {...rest}
    >
      {children}
    </MuiPopover>
  );
};

Popover.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
};

export default Popover;
