// React
import React from "react";
// Framework
import { MarkdownEditor } from "markdown";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = (props) => {
  const { form, field, height } = props;
  const { name, value } = field;
  // Handlers
  const handleChange = ({ text }) => {
    form.setFieldValue(name, text);
  };
  // Render
  return (
    <MarkdownEditor value={value} height={height} onChange={handleChange} />
  );
};

const FormMarkdownEditorField = (props) => (
  <FormField fullWidth component={Component} {...props} />
);

export default FormMarkdownEditorField;
