// React
import React, { Children, isValidElement } from "react";
import PropTypes from "prop-types";
// Framework
import { useTranslate } from "localization";
import {
  makeStyles,
  DialogActions,
  Button,
  Grid,
  Spacer,
  Box,
  Alert,
  Skeleton,
} from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  alert: {
    padding: theme.spacing(0, 1),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelDialogButtons = ({
  skeleton,
  loading,
  errorCount,
  onClose,
  children,
}) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { translate } = useTranslate();
  // Render
  return (
    <DialogActions>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        wrap="nowrap"
        spacing={1}
      >
        {errorCount > 0 && !loading && (
          <Grid item>
            <Alert severity="error" className={classes.alert}>
              {translate("core:model.dialog.buttons.error", {
                params: { count: errorCount },
              })}
            </Alert>
          </Grid>
        )}
        <Spacer />
        <Grid item>
          <Box color="action.active">
            <Button
              value="cancel"
              color="inherit"
              disabled={loading}
              onClick={onClose}
            >
              {skeleton && loading ? (
                <Skeleton variant="text" width={60} />
              ) : (
                <>{translate("core:model.dialog.buttons.cancel")}</>
              )}
            </Button>
          </Box>
        </Grid>
        {Children.map(children, (child, key) => (
          <>
            {isValidElement(child) && (
              <Grid item key={key}>
                {skeleton && loading ? (
                  <Skeleton variant="rect" width={60} height={32} />
                ) : (
                  child
                )}
              </Grid>
            )}
          </>
        ))}
      </Grid>
    </DialogActions>
  );
};

ModelDialogButtons.propTypes = {
  skeleton: PropTypes.bool,
  loading: PropTypes.bool,
  errorCount: PropTypes.number,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default ModelDialogButtons;
