// React
import React, { isValidElement, Children, useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import { includes, join, find } from "@mefisto/utils";
// Framework
import { Section } from "ui";
import { useTheme } from "theme";
import { useNavigationLayout } from "navigation";
// Components
import HeaderBar from "./components/HeaderBar";
import HeaderSection from "./components/HeaderSection";
import HeaderOuterContainer from "./components/HeaderOuterContainer";
import HeaderInnerContainer from "./components/HeaderInnerContainer";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Header = ({ children, ...headerProps }) => {
  // Framework
  const theme = useTheme();
  const {
    headerProps: {
      context = "header",
      value,
      display = "default",
      horizontalFill = "lg",
      horizontalInnerFill = "md",
      leadingSectionWidth,
      centerSectionWidth,
      trailingSectionWidth,
      position = "fixed",
      height = theme.sizes.header,
      backgroundColor = theme.palette.common.white,
      textColor = theme.palette.action.active,
      shadow,
    },
  } = useNavigationLayout({ headerProps });
  // Callback
  const getSection = useCallback(
    (variant) => {
      return find(children, (child) => {
        return isValidElement(child) && child.props.variant === variant;
      });
    },
    [children]
  );
  // Render
  return (
    <Section context={context} value={value}>
      <HeaderBar
        display={display}
        position={position}
        textColor={textColor}
        backgroundColor={backgroundColor}
        shadow={shadow}
      >
        <HeaderOuterContainer
          display={display}
          horizontalFill={horizontalFill}
          height={height}
        >
          <HeaderSection value="leading" width={leadingSectionWidth}>
            {getSection("leading")}
          </HeaderSection>
          <HeaderInnerContainer horizontalFill={horizontalInnerFill}>
            <HeaderSection fill value="center" width={centerSectionWidth}>
              {getSection("center")}
            </HeaderSection>
            <HeaderSection value="trailing" width={trailingSectionWidth}>
              {getSection("trailing")}
            </HeaderSection>
          </HeaderInnerContainer>
        </HeaderOuterContainer>
      </HeaderBar>
    </Section>
  );
};

Header.propTypes = {
  /**
   * Section context name
   */
  context: PropTypes.string,
  /**
   * Element specification value
   */
  value: PropTypes.string,
  /**
   * Defines display type.
   */
  display: PropTypes.oneOf(["default", "rounded"]),
  /**
   * Determine the fill size of the container.
   * The container width grows with the size of the screen.
   * Set to `true` to let the container fill the whole screen.
   */
  horizontalFill: PropTypes.oneOf([true, "lg", "md", "sm", "xl", "xs"]),
  /**
   * Determine the fill size of the inner container.
   * The container width grows with the size of the screen.
   * Set to `true` to let the container fill the whole outer container.
   */
  horizontalInnerFill: PropTypes.oneOf([true, "lg", "md", "sm", "xl", "xs"]),
  /**
   * Width of leading section
   */
  leadingSectionWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /**
   * Width of center section
   */
  centerSectionWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Width of trailing section
   */
  trailingSectionWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /**
   * Header bar position
   */
  position: PropTypes.oneOf([
    "fixed",
    "absolute",
    "sticky",
    "static",
    "relative",
  ]),
  /**
   * Height of header bar
   */
  height: PropTypes.number,
  /**
   * Header background color
   */
  backgroundColor: PropTypes.string,
  /**
   * Header text color
   */
  textColor: PropTypes.string,
  /**
   * Shadow below the header
   */
  shadow: PropTypes.string,
  /**
   * Children
   */
  children: ({ children }) => {
    Children.forEach(children, (child) => {
      const { variant } = child.props;
      const variants = ["leading", "center", "trailing"];
      if (!includes(variants, variant)) {
        throw new Error(
          `Only HeaderSection with variant=${join(
            variants,
            ","
          )} is allowed as child.`
        );
      }
    });
    return null;
  },
};

export default Header;
